
import GLOBAL from '../../../services/GLOBAL'
import LazyImage from '../../../services/LazyImage'
import lays from '../../../assets/lays-ipad-4.jpg'
import { useContext, useEffect, useRef } from 'react'
import { GlobalContext } from '../../../GlobalContext'

const CPFtoCNPJ = () => {

    const imageRef = useRef()
    const ref = useRef()

    const {weiManager} = useContext(GlobalContext)

    useEffect(() => {
        if (ref?.current) {
            weiManager.see(ref, 'topic-research')
        }
    }, [weiManager])

    useEffect(() => {
        const image = imageRef?.current
        if (!image) return

        const observer = new IntersectionObserver(([entry]) => {
            if (entry.isIntersecting) {
                if (!image.classList.contains('image-visible')) {
                    image.classList.add('image-visible')
                }
            }
        }, {
            threshold: .2
        })

        observer.observe(image)
        return () => observer.disconnect()

    }, [])

    return (
        <section className="cpf-to-cnpj">

            <div className="image-frame" ref={imageRef} >
                <LazyImage
                    className='image'
                    thumbnail={lays}
                    lightSource='/getImage/lays-ipad-4.jpg'
                    heavySource='/getImage/lays-ipad-4.png'
                    baseURL = { GLOBAL.BACKEND_BASE_URL }
                    alt='Lays em pé segurando um iPad'
                />
            </div>

            <span ref={ref}>
                <h1 className='slide-in-effect' >CPF reflete no CNPJ</h1>
                <p className='slide-in-effect' >“No final do dia, seja na vida pessoal ou nos negócios, os valores que defendemos são o que nos definem.</p>
                <p className='slide-in-effect' >As melhorias que todos buscamos - sejam como indivíduos ou como empreendedores - dependem diretamente do alinhamento entre nossos princípios e nossas ações.</p>
                <p className='slide-in-effect' >Clientes e parceiros de negócios são como espelhos: eles refletem os valores que suas empresas defendem.</p>
                <p className='slide-in-effect' >Portanto, a pergunta final é: Quais valores você está refletindo no seu empreendimento?</p>
                <p className='slide-in-effect' >Quando os valores estão alinhados, tanto o sucesso financeiro quanto a sustentabilidade e a longevidade do negócio tornam-se uma consequência natural.</p>
                <p className='slide-in-effect' >Na Primordial, afirmamos que as prosperidades verdadeiras só são alcançadas quando criamos uma sinergia entre CPF e CNPJ, promovendo soluções que garantem crescimento e sustentabilidade para todos os envolvidos.”</p>
                <p className='slide-in-effect' >- Lays Andrade, 29 de Outubro de 2024</p>
            </span>

        </section>
    )
}

export default CPFtoCNPJ;