


import GLOBAL from '../../../services/GLOBAL'
import LazyImage from "../../../services/LazyImage";
import Arrow from "../../../SVGR/Arrow";

import lays from '../../../assets/lays-ipad-3-2.jpg'
import { useEffect, useRef, useContext } from 'react';

import { GlobalContext } from '../../../GlobalContext';

const Hero = () => {

    const heroRef = useRef()
    const lastScrollRef = useRef(0)
    const contentRefs = useRef([])
    const imageFrameRef = useRef()

    const { smoothScrollTo } = useContext(GlobalContext)

    const handleClick = (e) => {
        e.preventDefault()
        if (!heroRef?.current) return

        const el = document.getElementById('deck')
        if (!el) return

        const elRect = el.getBoundingClientRect()
        
        const maxDistance = elRect.y
        const realDistance = Math.abs(elRect.y - window.scrollY)
        const ratio = realDistance / maxDistance

        smoothScrollTo(elRect.y + window.scrollY, Math.abs(1000 + 400 * ratio))

    }

    const handleScroll = () => {
        if (contentRefs.current.length === 0) return
        
        const [ref0, ref1, ref2] = contentRefs.current
        let ref1Rect = ref1.getBoundingClientRect()
        const refWidth = ref1Rect.width

        const lastScroll = lastScrollRef.current
        const currScroll = window.scrollY

        const offset = currScroll - lastScroll
        const newShift = Number.parseFloat(((offset * -1) / 5).toFixed(4))
        
        let lastLeft = ref1Rect.left
        
        if (Math.abs(lastLeft) >= refWidth) {

            ref0.style.left = `-${refWidth.toFixed(2)}px`
            ref1.style.left = '0px'
            ref2.style.left = `${refWidth.toFixed(2)}px`

            ref1Rect = ref1.getBoundingClientRect()
            lastLeft = ref1Rect.left
        }

        requestAnimationFrame(() => {
            ref0.style.left = `${(lastLeft + newShift - refWidth).toFixed(2)}px`
            ref1.style.left = `${(lastLeft + newShift).toFixed(2)}px`
            ref2.style.left = `${(lastLeft + newShift + refWidth).toFixed(2)}px`
        })

        lastScrollRef.current = currScroll
    }
    
    useEffect(() => {

        const handleResize = () => {
            handleScroll()
        }
      
        const resizeObserver = new ResizeObserver(handleResize)
        resizeObserver.observe(document.documentElement)
      
        return () => {
            resizeObserver.disconnect()
        }
    }, []);

    useEffect(() => {
        document.addEventListener('scroll', handleScroll)
        document.addEventListener('wheel', handleScroll)

        return () => {
            document.removeEventListener('scroll', handleScroll)
            document.removeEventListener('wheel', handleScroll)
        }
    }, [])

    useEffect(() => {
        const imageFrame = imageFrameRef?.current
        if (!imageFrame) return

        const observer = new IntersectionObserver(([entry]) => {
            if (entry.isIntersecting) {
                
                if (!imageFrame.classList.contains('visible')) imageFrame.classList.add('visible')
            }
        }, {
            threshold: .2
        })

        observer.observe(imageFrame)

        return () => observer.disconnect()

    }, [])

    return (
        <section className="hero" ref={heroRef}>
            
            <div className="top">
                <span>
                    <h1 className='slide-in-effect'>
                        Quem é a Lays <br/> e a Primordial?
                    </h1>
                    <p className='slide-in-effect'>
                        Conheça a nossa história, propósito, a maneira como trabalhamos&nbsp;
                        e porque escolhemos o ESG para atuar.
                    </p>
                    <a href="#are-reports-enough" className='slide-in-effect' onClick={handleClick}>
                        Veja abaixo
                        <Arrow className='arrow' bodyLength={2.7} />
                    </a>
                </span>
            </div>

            <div className="bottom">
                <div className="slider">
                    <div className="content" ref={ e => contentRefs.current[0] = e }>
                        Prosperidade & Sustentabilidade 
                    </div>
                    <div className="content" ref={ e => contentRefs.current[1] = e }>
                        Prosperidade & Sustentabilidade 
                    </div>
                    <div className="content" ref={ e => contentRefs.current[2] = e }>
                        Prosperidade & Sustentabilidade 
                    </div>
                </div>
            </div>

            <div className="image-frame" ref={imageFrameRef} >
                <LazyImage
                    className='image'
                    thumbnail={lays}
                    lightSource='/getImage/lays-ipad-3-2.jpg'
                    heavySource='/getImage/lays-ipad-3-2.png'
                    baseURL = { GLOBAL.BACKEND_BASE_URL }
                    alt='Lays em pé segurando um Ipad'
                />
            </div>

        </section>
    )
}

export default Hero;