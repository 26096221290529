
import { useContext } from "react"
import { GlobalContext } from "../../../GlobalContext"
import { useNavigate } from "react-router-dom"

const AcceptCookies = ({ hidePopup = () => {} }) => {

    const { allowCookies, declineCookies } = useContext(GlobalContext)
    const navigate = useNavigate()

    const handleAccept = () => {
        allowCookies()
        hidePopup()
    }

    const handleReject = () => {
        declineCookies()
        hidePopup()
    }

    const handleLink = (e) => {
        e.preventDefault()
        navigate('/policy')
    }

    return (
        <div className="accept-cookies">
            <h1>🍪🍪🍪</h1>
            <p>
                Nós respeitamos a sua privacidade. Os cookies que utilizamos ajudam a melhorar sua experiência e não armazenam dados pessoais. Saiba mais em nossa&nbsp;
                <br />
                <a href="/policy" onClick={ handleLink } rel="nofollow" >Política de Privacidade</a>.
            </p>
            <button className="default-button" onClick={ handleAccept } >Aceitar Cookies!</button>
            <button className="default-button" onClick={ handleReject } >Rejeitar</button>
        </div>
    )
}

export default AcceptCookies;