
import React, { useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import './App.css';
import AppRoutes from './AppRoutes.jsx'

import { GlobalContext, GlobalProvider } from "./GlobalContext.jsx";

import FadeInEffectController from './animation-controllers/FadeInEffectController';
import SlideInEffectController from "./animation-controllers/SlideInEffectController.jsx";
import PopUpDisplay from './components/PopUpDisplay/index.jsx';
import FloatingMetrics from './modules/FloatingMetrics.jsx';

function App() {

    const location = useLocation()

    useEffect(() => {
        const handleBeforeInstallPrompt = (e) => {
          // Prevent browser from showing the install PWA pop-up
          e.preventDefault();
        };
    
        window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    
        // Cleanup event listener on component unmount
        return () => {
          window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
        };
      }, []);

    return (
        <div className="App">
          <GlobalProvider> {/* Provides global context to share the application's WEIManager instance across components */}
            
            <FadeInEffectController key={location.pathname} /> {/* Controls Fade In Effects */}
            <SlideInEffectController key={location.pathname+'-2'} /> {/* Controls Slide In Effects */}
            <VisitedPagesHandler />
            
            <PopUpDisplay />
            
            <AppRoutes />
            {/* <FloatingMetrics /> */}

          </GlobalProvider>
        </div>
    );
}

function VisitedPagesHandler() {
  const location = useLocation()
  const {mainPages, addVisitedPage} = useContext(GlobalContext)

  useEffect(() => {
    if (!mainPages || mainPages.length === 0) return;

      const visitedPage = mainPages.find(page => page.url === location.pathname)
      if (!visitedPage) return

      addVisitedPage(visitedPage.name, visitedPage.url)

  }, [addVisitedPage, location, mainPages]);
  
  return null
}

export default App;
