

import { useContext, useEffect, useRef } from 'react'
import Arrow from '../../../SVGR/Arrow'
import { GlobalContext } from '../../../GlobalContext'

import lays from '../../../assets/standing-lays-notebook.jpg'

import LazyImage from '../../../services/LazyImage'
import GLOBAL from '../../../services/GLOBAL'

const LearnMoreAboutESG = () => {

    const articles = [
        {
            title: 'Como o o ESG impulsiona o Mercado Imobiliário',
            url: 'https://www.linkedin.com/posts/lays-engenheira-ambiental_esg-no-mercado-imobili%C3%A1rio-vem-com-a-gente-activity-7265815858210304002-k8wq?utm_source=share&utm_medium=member_desktop'
        },
        {
            title: 'Itaú investe 1 trilhão de reais na agenda ESG!',
            url: 'https://www.linkedin.com/posts/lays-engenheira-ambiental_esg-no-mercado-imobili%C3%A1rio-activity-7264412182497366016-zTFN?utm_source=share&utm_medium=member_desktop'
        },
        {
            title: 'Agenda ESG para Empreendimentos Imobiliários',
            url: 'https://www.linkedin.com/posts/lays-engenheira-ambiental_imobiliario-esg-consultoriaambiental-activity-7254617566411702272-F0m7?utm_source=share&utm_medium=member_desktop'
        },
        {
            title: 'Por quais valores seus filhos e clientes te admiram?',
            url: 'https://www.linkedin.com/posts/lays-engenheira-ambiental_seu-cnpj-est%C3%A1-alinhado-ao-seu-cpf-leia-esse-activity-7256767760095928320-258G?utm_source=share&utm_medium=member_desktop'
        } 
    ]

    const imageFrameRef = useRef()
    const linksContainerRef = useRef()

    const {weiManager} = useContext(GlobalContext)

    useEffect(() => {
        if (!imageFrameRef?.current) return

        const observer = new IntersectionObserver(([entry]) => {
            if (entry.isIntersecting) {
                imageFrameRef.current.classList.add('visible')
                observer.disconnect()
            }
        }, { threshold: 0.1 })

        observer.observe(imageFrameRef.current)

        return () => observer.disconnect()
    }, [])

    useEffect(() => {
        if (linksContainerRef?.current) {
            weiManager.see(linksContainerRef, 'company-interest')
        }
    }, [weiManager])

    return (
        <section className="learn-more-about-esg">

            <div className="image-frame" ref={imageFrameRef} >
                <LazyImage 
                    className='image'
                    alt="Lays em pé usando um notebook sobre um mesa"
                    thumbnail = { lays }
                    lightSource = { 'getImage/standing-lays-notebook.jpg' }
                    heavySource = { 'getImage/standing-lays-notebook.png' }
                    baseURL = { GLOBAL.BACKEND_BASE_URL }
                />
            </div>

            <div className="content">

                <h1 className='slide-in-effect'>
                    Saiba mais sobre ESG com esses artigos no LinkedIn
                </h1>

                <div className="links-container slide-in-effect" ref={linksContainerRef} >{ articles.map( (article, index) => (
                    <a
                        href={article.url}
                        className='link'
                        rel='noopener noreferrer'
                        target='_blank'
                        key={'learn-more-about-esg-article-'+index}
                    >
                        {article.title}
                        <div className="arrow-container">
                            <Arrow bodyLength={2.5} />
                        </div>
                    </a>
                )) }</div>

            </div>

        </section>
    )
}

export default LearnMoreAboutESG;