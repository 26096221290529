
import './main.css'

import Nav from '../Nav'

import Hero from "./components/Hero"
import ServicesSection from "./components/ServicesSection"
import ESGWorth from "./components/ESGWorth"
import WhatESGIs from "./components/WhatESGIs"
import OurProjects from "./components/OurProjects"
import FeedbacksSection from "./components/FeedbacksSection"
import QuizSection from "./components/QuizSection"
import VideoSection from "./components/VideoSection"
import IsESGForMe from "./components/IsESGForMe"
import LearnMoreAboutESG from "./components/LearnMoreAboutESG"
import ESGIsThePresent from "./components/ESGIsThePresent"
import FAQSection from "./components/FAQSection"
import AskForProposal from "./components/AskFroProposal"
import Footer from "../Footer"
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

export default function Home() {

    const location = useLocation();

    useEffect(() => {
        const savedPosition = sessionStorage.getItem("scrollPosition");
        if (savedPosition) {
        window.scrollTo(0, parseInt(savedPosition, 10));
        }

        return () => {
        sessionStorage.setItem("scrollPosition", window.scrollY);
        };
    }, [location.pathname]);

    return (<div className="home-page">

        <Nav />
        <Hero />
        <ServicesSection />
        <ESGWorth />
        <WhatESGIs />
        {/*
            <OurProjects />
            <FeedbacksSection />
        */}
        <QuizSection />
        {/*
            <VideoSection />
        */}
        <IsESGForMe />
        <LearnMoreAboutESG />
        <ESGIsThePresent />
        <FAQSection />
        <AskForProposal />
        <Footer />

    </div>)
}

