
import LazyImage from '../../../services/LazyImage'
import GLOBAL from '../../../services/GLOBAL'

import { GlobalContext } from '../../../GlobalContext'

import X from '../../../SVGR/X'

import lays from '../../../assets/lays-ipad-2.png'
import { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'


const WelcomeBack = ({ hidePopup }) => {

    const { getUserFirstName, getVisitedPages, mainPages, mobile } = useContext(GlobalContext)
    const navigate = useNavigate()
    
    const [visitedPages] = useState(getVisitedPages())
    const [nonVisitedPages, setNonVisitedPages] = useState([])
    const [userFirstName] = useState(getUserFirstName())

    useEffect(() => {
        const nonVisited = mainPages.filter(({ name: pageName }) => {
            return !visitedPages.find(({ name }) => name === pageName)
        })
        setNonVisitedPages(nonVisited)
    }, [mainPages, visitedPages])

    return (
        <div className="welcome-back">

            <span>

                <h1>
                    Olá
                    {
                        userFirstName
                            ? `, ${userFirstName}!`
                            : '!' 
                    }
                    {
                        mobile
                            ? '😉'
                            : null
                    }
                </h1>

                <p>
                    Estamos prontos para te ajudar a alcançar seus objetivos no mercado imobiliário.
                    {
                        !mobile
                            ? ' 😉'
                            : null
                    }
                </p>
                <ul>
                    { visitedPages?.length > 0 &&
                        <li>
                            <div className="circle"></div>
                            Ver novamente: { visitedPages.map(({name, url}, index) => 
                                <a href={url} key={'welcome-back-visited-page-'+index} onClick={ e => {e.preventDefault(); navigate(url) }} >
                                    { name }
                                    { index < nonVisitedPages.length - 1 ? ', ' : '.'}
                                </a>
                            )}
                        </li>
                    }
                    
                    { nonVisitedPages?.length > 0 &&
                        <li>
                            <div className="circle"></div>
                            Descobrir: { nonVisitedPages.map(({name, url}, index) => 
                                <a href={url} key={'welcome-back-non-visited-page-'+index} onClick={ e => {e.preventDefault(); navigate(url) }} >
                                    { name }
                                    { index < nonVisitedPages.length - 1 ? ', ' : '.'}
                                </a>
                            )}
                        </li>
                    }
                    
                    
                </ul>
                <a
                    href="/requestForProposal"
                    className='cta default-button'
                    onClick={ e => {
                        e.preventDefault()
                        navigate('/requestForProposal')
                    }}
                >
                    Agende uma reunião!
                </a>
            </span>
            
            <div className="image-container">
                <LazyImage
                    className='image'
                    thumbnail={lays}
                    lightSource='/getImage/lays-ipad-2-light.png'
                    heavySource='/getImage/lays-ipad-2.png'
                    baseURL = { GLOBAL.BACKEND_BASE_URL }
                    alt='Lays em pé segurando um Ipad'
                />
            </div>

            <div className='x-container default-button'
                onClick={ e => {
                    e.preventDefault()
                    hidePopup(e)
                }}
            >
                <X className='x' />
            </div>
        </div>
    )
}

export default WelcomeBack;