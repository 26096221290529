
import { useState, useEffect } from 'react';

import Nav from '../Nav'
import Footer from '../Footer'

import './main.css'
import Hero from './components/Hero'
import Deck from './components/Deck'
import WhyWeWork from './components/WhyWeWork';
import Methodology from './components/Methodology';
import CPFtoCNPJ from './components/CPFtoCNPJ';
import WeUseTech from './components/WeUseTech';
import Contact from './components/Contact';

//import GLOBAL from '../../services/GLOBAL'

const About = () => {

    const [colleagues, setColleagues] = useState([
        {
            "name": "Ana Costa",
            "jobTitle": "Fundadora e CEO",
            "imageFileName": "ana-costa.png"
        },
        {
            "name": "Mariana Silva",
            "jobTitle": "Gerente de Projetos Ambientais",
            "imageFileName": "mariana-silva.png"
        },
        {
            "name": "Carlos Pereira",
            "jobTitle": "Diretor",
            "imageFileName": "carlos-pereira.png"
        },
        {
            "name": "Rafael Nogueira",
            "jobTitle": "Engenheiro Ambiental",
            "imageFileName": "rafael-nogueira.png"
        }
    ]);

    useEffect(() => {
        /* window.scrollTo(0, 0);

        const fetchColleagues = async () => {
            try {
                const { colleagues } = await GLOBAL.CONTENT;
                setColleagues(colleagues);
            } catch (error) {
                console.error('Failed to fetch Colleagues data:', error);
            }
        };

        fetchColleagues();
        */
    }, [])


    return (
        <div className="about-page">
            <Nav />
            <Hero />
            <Deck />
            <WhyWeWork />
            {/*
                <Methodology />
            */}
            <CPFtoCNPJ />
            <WeUseTech />
            <Contact />
            <Footer />
        </div>
    )
}

export default About;