
/* WEI = Weighted Engagement Index */

export default class WEIManager {

    #log = []
    #cleanupList = []

    #matters = new WEIMattersList()

    #timestamp = 0
    #clock = 100 // Only updates local data at least every 100 miliseconds
    #states = {} // Adptable states. It will be used like a local memory storage

    #idleTimer
    #isIdle = false

    #elementsBeingObserved = []
    #allowedToSaveData = false

    constructor(apiInterface) {

        if (apiInterface) {
            if (apiInterface?.getData && apiInterface?.setData) {
                this.setInterface(apiInterface)
            } else {
                throw new Error("apiInterface must have 'getData' and 'setData' functions in it")
            }
        }

        this.#pullData()
        let threadCleanup = this.#autoSync()
        this.#addCleanup("WEIManager autosync thread cleanup", threadCleanup)

        this.#run()

        // Idle detection

        this.#states.idleTimerTimestamp = Date.now()

        const reachedClock = () => {

            if (Date.now() - this.#states.idleTimerTimestamp >= this.#clock) {

                this.#states.idleTimerTimestamp = Date.now()
                return true

            } else {
                return false
            }
        }

        const resetIdleTimer = () => {

            if (this.#idleTimer) {
                clearTimeout(this.#idleTimer); // Removes previous timers before adding new ones
            }
          
            this.#idleTimer = setTimeout(() => { // Assumes idleness after 10 seconds of inactivity
                this.#isIdle = true;
            }, 10000);

        }

        const handleUserInteraction = () => {
            if (!reachedClock()) return // Limits excessive executions

            if (this.#isIdle) {
                this.#isIdle = false;
            }
          
            resetIdleTimer();
        }

        const handleVisibilityChange = () => {

            if (document.hidden) {

                this.#isIdle = true // Assumes idleness if the window isn't visible
                clearTimeout(this.#idleTimer)

            } else {

                this.#isIdle = false // Assumes active after the window becomes visible
                resetIdleTimer()
            }
        }

        document.addEventListener('mousemove', handleUserInteraction);
        document.addEventListener('scroll', handleUserInteraction);
        document.addEventListener('visibilitychange', handleVisibilityChange);

        // Remove listeners and stop the remaining idle timer
        this.#addCleanup('WEIManager idle timer cleanup', () => {

            if (this.#idleTimer) clearTimeout(this.#idleTimer)

            document.removeEventListener('mousemove', handleUserInteraction);
            document.removeEventListener('scroll', handleUserInteraction);
            document.removeEventListener('visibilitychange', handleVisibilityChange);

        })

    }

    /**
     * Enables the manager to save engagement data.
     * Until this method is called, no data will be saved.
     * @returns {WEIManager} The WEIManager instance itself
     */
    allowSavingData() {
        this.#allowedToSaveData = true
        return this
    }

    /**
     * Disables data saving and removes all data stored.
     * After calling this method, no further data will be saved.
     * @returns {WEIManager} The WEIManager instance itself
     */
    declineSavingData() {
        this.#allowedToSaveData = false
        return this
    }

    #onLog = (log) => {}
    /**
     * 
     * @param {function} callback The code to run when the WEIManager adds something to de log. The callback recieves the log as param.
     * @returns {WEIManager} The WEIManager instance itself
     * @example
     * onLog(
     *   (log) => {
     *     console.log(log.error)
     *     console.log(log.context)
     *     console.log(log.timestamp)
     *   }
     * )
     */
    onLog(callback = (log) => {}) {
        this.#onLog = callback
        return this
    }

    /**
     * 
     * @param {*} log 
     * @param {Error} log.error The error that ocurred
     * @param {String} log.context Where the error ocurred 
     * @param {String} log.timestamp The error time in ISOString format
     * @returns {WEIManager} The WEIManager instance itself
     * @example
     * try {
     *   // Your code
     * } catch (error) {
     * 
     *   const log = {
     *     error,
     *     context: "Error running your code",
     *     timestamp: new Date().toISOString()
     *   }
     * 
     *   addLog(log)
     * 
     * }
     */
    addLog(log) {

        this.#log.push(log)

        try {
            this.#onLog(log)
        } catch (err) {
            console.error(new Error(
                'Error running the callback function passed in onLog()',
                {
                    cause: err
                }
            ))
        }

        return this
    }

    /**
     * Return all the metrics by matter
     * @returns { WEIMattersList }
     */
    getList() {
        return this.#matters
    }

    /**
     * Checks if have been passed some time since last execution - It's used to control overcalling
     * @returns {boolean}
     */
    #isToRun() {
        return Date.now() - this.#timestamp >= this.#clock
    }

    /**
     * Resets the executcion timestamp - Calling this method delays executions that demands of the #isToRun() function
     */
    #run() {
        this.#timestamp = Date.now()
    }

    #api = {
        getData() {},
        setData() {}
    }
    /**
     * Sets a data interface for the WEIManager, allowing it to save and retrieve engagement data.
     * This method accepts an API object with two functions: `getData` and `setData`, which handle
     * the retrieval and storage of data, respectively.
     * 
     * @param {Object} [api] - An object containing the interface for data handling.
     * @param {function} [api.getData] - A function that retrieves the saved WEI data. Should return the data when called.
     * @param {function} [api.setData] - A function that saves the provided WEI data. Should accept the data as an argument.
     * 
     * @returns {WEIManager} The WEIManager instance itself
     * 
     * @example
     * // Example usage:
     * const apiInterface = {
     *     getData: () => JSON.parse(localStorage.getItem('weiData')),
     *     setData: (data) => localStorage.setItem('weiData', JSON.stringify(data))
     * };
     * manager.setInterface(apiInterface);
     */
    setInterface(api = { getData: () => {}, setData: () => {} }) {
        this.#api = api
        return this
    }

    /**
     * Retrieves data from the configured data interface (`getData`) and synchronizes it 
     * with the internal data structure of the WEIManager. 
     * If an error occurs or the data is invalid, it initializes an empty `WEIMattersList`.
     * @returns {WEIManager} The WEIManager instance itself
     * 
     * @throws {Error} Throws an error if the data interface fails during data retrieval.
     *                 The original error is included as the `cause` property.
     */
    #pullData() {

        try {
            let rawWEIData = this.#api.getData() || {}
            if (typeof rawWEIData?.then === 'function') {
                rawWEIData.then(rawWEIData => {
                    pull(rawWEIData)
                })
            } else {
                pull(rawWEIData)
            }

            function pull(rawWEIData) {
                if (rawWEIData && Object.keys(rawWEIData).length > 0) {
                    this.#matters = new WEIMattersList().from(rawWEIData)
                }
            }
        } catch (err) {
            this.#matters = new WEIMattersList()

            throw new Error(
                'WEIManager encountered an error when pulling data.',
                { cause: err }
            );
        }
        return this
    }

    /**
     * Saves the internal data structure of the WEIManager to the configured 
     * data interface (`setData`), overwriting any existing data.
     * @returns {WEIManager} The WEIManager instance itself
     * 
     * @throws {Error} Throws an error if the data interface fails during data saving.
     *                 The original error is included as the `cause` property.
     */
    #pushData() {
        try {
            const dataOrPromise = this.#api.getData();

            if (typeof dataOrPromise?.then === "function") {
                dataOrPromise
                    .then(use.bind(this))
                    .catch(err => {
                        this.addLog({
                            error: err,
                            context: "WEI_Manager encountered an error when pushing data",
                            timestamp: new Date().toISOString()
                        })
                    })
            } else {
                use(dataOrPromise)
            }

            function use(data) {

                const prevData = data
                    ? new WEIMattersList().from(data)
                    : new WEIMattersList()

                this.#matters.combine(prevData)

                this.#api.setData(this.#matters.getRaw())

            }
            
        } catch (err) {
            throw new Error(
                'WEIManager encountered an error when pushing data.',
                { cause: err }
            );
        }
        return this
    }

    /**
     * Sends internal data to localStorage periodically
     * @returns {function} Cleanup function that stops the remaining timer created by itself
     */
    #autoSync() {
        // Pushes WEI_DATA every 10 seconds
        const thread = setTimeout(() => {
            this.#autoSync()
            this.syncMetrics()
        }, 10000) 

        return () => { clearTimeout(thread) }

    }

    /***
     * Sends internal data to localStorage
     * @returns {WEIManager} The WEIManager instance itsef
     */
    syncMetrics() {
        if (this.#allowedToSaveData) {
            this.#pushData()
        }
        return(this)
    }
    
    // =============================
    // |  WEI_DATA CRUD operations |
    // =============================

    /**
     * Overwrites metrics related to a matter
     * @param {EngagementMetrics} metrics - The new metrics to overwrite
     * @param {String} matter - The matter related to the metrics
     * @returns {WEIManager} The WEIManager instance itsef
     */
    writeMetrics(metrics = new EngagementMetrics(), matter) {
        this.#matters.setMetrics(matter, metrics);
        return(this)
    }

    /**
     * 
     * @param {String} matter 
     * @returns {EngagementMetrics} Metrics related to the passed matter
     * @returns Returns 'null' If no metrics match to the requested matter
     */
    getMetrics(matter) {
        return this.#matters.getMetrics(matter)
    }

    /**
     * Set the metrics related to the passed matter to the callback return
     * @example
     * const wei = new WEIManager();
     * wei.updateMetrics((prevMetrics) => prevMetrics.setVisibleTime(5000), 'main');
     * // main metrics has now visibleTime setted to 5000
     * 
     * @param {function} callback 
     * @param {string} matter
     * @returns {WEIManager} The WEIManager instance itsef
     */
    updateMetrics(callback = (prev) => { return prev }, matter) {
        this.writeMetrics(callback(this.#matters.getMetrics(matter)), matter);
        return(this)
    };

    /**
     * 
     * @param {String} matter Matter to erase related metrics
     * @returns {WEIManager} The WEIManager instance itsef
     */
    eraseMetrics(matter) {
       this.#matters.dropMetrics(matter)
       return(this)
    }

    // Function to observe React.js refs (Applies listeners and other logics to define WEI afterly)

    /**
     * Adds engagement measuring features to get metrics from a React ref.
     *
     * @param {React.RefObject<HTMLElement>} ref React ref to be observed.
     * @param {string} matter Matter related to the React ref.
     * @returns {WEIManager} The WEIManager instance itsef
     */
    see(ref, matter) {

        if (!matter) {
            console.warn("WEIManager.see() does nothing when 'matter' is undefined:")
            return
        }

        if (!ref?.current) return
        if (this.#elementsBeingObserved.includes(ref.current)) return

        // Visibiility dealing
    
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {

                    if (!this.getMetrics(matter)) {
                        this.writeMetrics(new EngagementMetrics(), matter)
                    }
                    
                    const period = 1000 // 1 second

                    const interval = setInterval(() => {
                        if (this.#isIdle) return

                        // Increment matter visible time
                        this.updateMetrics(prevMetrics => prevMetrics.setVisibleTime( prevTime => prevTime + period ), matter)

                    }, period); // Run every 1 second
        
                    // Save the interval ID to use later
                    entry.target._interval = interval;
    
                } else {
                    clearInterval(entry.target._interval); // Clear the interval since the element is no longer visible
                }
            },
            { threshold: 0.5 } // // Trigger when the element is at least 50% visible
        );
    
        // Interaction dealing

        const handleMouseMove = (event) => {

            // Limits calls to optmize processig
            if (this.#isToRun()) {
                this.#run()
            } else {
                return
            }

            // Reading logic

            const mouseX = event.clientX;
            const mouseY = event.clientY;

            const resetReadingTimeStamp = () => ( this.#states.readingTimeStamp = Date.now() )
        
            if (isMouseOverText(mouseX, mouseY)) {
                
                if (!this.#states.readingTimeStamp) resetReadingTimeStamp()

                const elapsedTime = Date.now() - this.#states.readingTimeStamp

                if (elapsedTime <= 5000) { // Ignore if the cursor remains inactive for more than 5 seconds.

                    this.updateMetrics(prevMetrics => {
                        if (!prevMetrics) prevMetrics = new EngagementMetrics()
                        prevMetrics.setTxtInteractionTime( prevTime => prevTime + elapsedTime )
                        return prevMetrics
                    }, matter)

                    resetReadingTimeStamp()

                } else {
                    resetReadingTimeStamp()
                }

            }

        }

        // Find the ref buttons and links

        const handleOverBtn = (event) => {
            // Limits calls to optmize processig


            if (this.#isToRun()) {
                this.#run()
            } else {
                return
            }

            // Over buttons logic

            if (!this.#states.overBtnsTimeStamp) this.#states.overBtnsTimeStamp = Date.now()

            let elapsedTime = Date.now() - this.#states.overBtnsTimeStamp

            if (elapsedTime <= 1000) { // Ignore if the cursor remains more than 1s or less than 0,3s inactive
                this.updateMetrics(prevMetrics => {
                    if (!prevMetrics) prevMetrics = new EngagementMetrics()
                        prevMetrics.setOverBtnsTime(prevTime => prevTime + elapsedTime)

                    return prevMetrics
                }, matter)
                this.#states.overBtnsTimeStamp = Date.now()
                
            } else {
                this.#states.overBtnsTimeStamp = Date.now()
            }

        }

        const handleBtnClick = (event) => {
            this.updateMetrics(prevMetrics => {
                if (!prevMetrics) prevMetrics = new EngagementMetrics()
                    prevMetrics.setBtnClicks(prevClicks => prevClicks + 1)

                return prevMetrics
            }, matter)
        }

        const btnsAndLinks = getButtonsAndLinks()
    

        // Listeners dealing

        const addEventListeners = () => {
            ref.current.addEventListener('mousemove', handleMouseMove)

            btnsAndLinks.forEach( btnOrLink => {
                if (btnOrLink) {
                    btnOrLink.addEventListener('mousemove', handleOverBtn)
                    btnOrLink.addEventListener('click', handleBtnClick)
                }
            })
        }
    
        const removeEventListeners = () => {
            if (ref?.current) {
                ref.current.removeEventListener('mousemove', handleMouseMove)
            }
            
            btnsAndLinks.forEach( btnOrLink => {
                if (btnOrLink) {
                    btnOrLink.removeEventListener('mousemove', handleOverBtn)
                    btnOrLink.removeEventListener('click', handleBtnClick)
                }
            })
        }

        // Start observing
        observer.observe(ref.current);
        this.#elementsBeingObserved.push(ref.current)
        addEventListeners();
    
        // Add the main cleanup
        this.#addCleanup(`${ref.current.id || "WEIManager: UNDEFINED_DOM_ELEMENT_ID"} observing cleanup`, () => {
            observer.disconnect();
            removeEventListeners();
        })

        return(this)

        // AUXILIAR FUNCTIONS

        function getButtonsAndLinks() {
            
            if (!ref.current) return [];

            const elements = ref.current.querySelectorAll("button, a")
            return Array.from(elements)

        }

        function isMouseOverText(mouseX, mouseY) {
            const rects = getTextRects(ref.current)
            return rects.some(rect => {
                return mouseX >= rect.left && mouseX <= rect.right && mouseY >= rect.top && mouseY <= rect.bottom;
            });
        }

        function getTextRects(element) {

            const textNodes = [];
    
            const walker = document.createTreeWalker(
                element,
                NodeFilter.SHOW_TEXT,
                null,
                false
            );
    
            let node;
            while ((node = walker.nextNode())) {
                if (node.textContent.trim() !== '') {
                    textNodes.push(...getRects(node));
                }
            }
    
            return textNodes;
    
            // ====================================================
    
            function getRects(textNode) {
    
                const range = document.createRange();
    
                range.selectNodeContents(textNode); // Selects the node content
                const rects = range.getClientRects(); // Get the rect of each text row
    
                range.detach();
            
                // Filters invisible text rows
                return Array.from(rects).filter(rect => rect.width > 0 && rect.height > 0);
            }
    
        }

    }

    /**
     * Shows all the log list errors in the console
     * @returns {WEIManager} The WEIManager instance itsef
     */
    printLog() {
        if (this.#log.length === 0) {
            console.log('No log errors to show.')
        }
        this.#log.forEach((log) => { console.error(log.error) })
        return(this)
    }
    
    /**
     * Adds logic to the main cleanup function
     * @param {String} name 
     * @param {function} callback
     * @returns {WEIManager} The WEIManager instance itsef
     */
    #addCleanup(name, callback) {
        this.#cleanupList.push({ name, run: callback })
        
        return(this)
    }

    /**
     * Run all the cleanup list functions
     * @returns {WEIManager} The WEIManager instance itsef
     */
    cleanupFunction() {
        this.#cleanupList.forEach((currentCleanup, _, array) => {
            try {
                currentCleanup.run()
                this.#cleanupList = array.filter(cleanup => cleanup !== currentCleanup)
            } catch (err) {

                this.addLog({
                    error: err,
                    context: currentCleanup.name,
                    timestamp: new Date().toISOString()
                });

                console.error('"Error running one of the useWEI cleanup functions. Run "YOUR_WEI_MANAGER_INSTANCE.printLog()" to more details.')
            }
        })
        
        return(this)
    }

}

export class WEIMattersList {

    #rawList = {}

    /**
     * 
     * @returns {Object} Propertys reduced to simple JSON compatible Object structure
     */
    getRaw() {
        let matters = {...this.#rawList}

        for (let key in matters) {
            matters[key] = matters[key].getRaw()
        }

        return {
            matters
        }
    }

    /**
     * Parses simple data to WEIMattersList structure
     * @param {Object} raw JSON compatible Object structure to parse
     * @returns {WEIMattersList} The WEIMattersList instance itself
     */
    from(raw) {
        
        for (let key in raw.matters) {
            this.#rawList[key] = new EngagementMetrics().from(raw.matters[key])
        }

        return this
    }

    /**
     * 
     * @param {String} matter Matter name 
     * @returns {EngagementMetrics} Metrics related to the requested matter
     * @returns Returns 'null' If no metrics match to the requested matter
     */
    getMetrics(matter) {
        return this.#rawList[matter] ? this.#rawList[matter] : null
    }

    /**
     * Erase metrics related to the passed matter
     * @param {String} matter 
     * @returns {WEIMattersList} The WEIMattersList instance itself
     */
    dropMetrics(matter) {
        delete this.#rawList[matter]
        return this
    }

    /**
     * Sets metrics related to the passed matter to the value or the callback return
     * @example
     * const list = new WEIMattersList();
     * list.setMetrics('main', (prevMetrics) => prevMetrics.setVisibleTime(5000));
     * // main metrics visibleTime is now setted to 5000
     * @param {String} matter 
     * @param {EngagementMetrics|function} valueOrCallback 
     * @returns {WEIMattersList} The WEIMattersList instance itself
     */
    setMetrics(matter, valueOrCallback = (prev = new EngagementMetrics()) => prev) {

        if (typeof valueOrCallback === "function") {

            const newMetrics = valueOrCallback(this.#rawList[matter])

            if (newMetrics instanceof EngagementMetrics) {
                this.#rawList[matter] = newMetrics;
            } else {
                throw new Error(
                    `"valueOrCallback" must return an instace of the EngagementMetrics class. Found "${
                        newMetrics?.constructor?.name || typeof valueOrCallback
                    }" instead.`
                )
            }

        } else {
            if (valueOrCallback instanceof EngagementMetrics) {
                this.#rawList[matter] = valueOrCallback;
            } else {
                throw new Error(
                    `"valueOrCallback" must be or return an instace of the EngagementMetrics class. Found "${
                        valueOrCallback?.constructor?.name || typeof valueOrCallback
                    }" instead.`
                )
            }
        }

        return this
    }
    
    /**
     * Combines the current metrics with another set of metrics, returning the maximum value 
     * for each metric in cases of overlap. Metrics not present in the current list but present 
     * in the provided list are added to the current list.
     * 
     * @param {WEIMattersList} matters - An instance of `WEIMattersList` containing the metrics to combine with.
     *                                   Each metric is represented as a key-value pair where the key is the 
     *                                   metric identifier, and the value is an `EngagementMetrics` object.
     * 
     * @throws {TypeError} Throws an error if `matters` is not an instance of `WEIMattersList`.
     * @returns {WEIMattersList} The WEIMattersList instance itself
     * 
     * @example
     * // Example usage:
     * const myMetrics = new WEIMattersList();
     * const otherMetrics = new WEIMattersList();
     * 
     * myMetrics.combine(otherMetrics); // Combines the two metrics lists.
     */
    combine(matters) {

        if (!(matters instanceof WEIMattersList)) {
            throw new TypeError("'matters' must be an instance of class WEIMattersList")
        }

        const othersList = matters

        let matterKeys = Object.keys(this.#rawList)

        const othersKeys = Object.keys(othersList.getRaw().matters)
            .filter( key => !matterKeys.includes(key) )

        matterKeys = [
            ...matterKeys,
            ...othersKeys
        ]

        let combinedRaw = {}
        matterKeys.forEach(matter => {

            const defaultMetrics = new EngagementMetrics().getData()
            const selfMetrics = this.getMetrics(matter)?.getData() || defaultMetrics
            const othersMetrics = othersList.getMetrics(matter)?.getData() || defaultMetrics
            const combinedMetrics = {}
            
            Object.keys(selfMetrics)
                .forEach(key => {
                    combinedMetrics[key] = Math.max(
                        selfMetrics[key],
                        othersMetrics[key]
                    )
                }
            )

            combinedRaw[matter] = new EngagementMetrics().from({ metrics: combinedMetrics })

        })

        Object.keys(combinedRaw).forEach(metrics => {
            this.setMetrics(metrics, () => combinedRaw[metrics])
        })
        return this
    }

    /**
     * Calculates the total engagement metrics by summing all individual metrics.
     * 
     * @returns {EngagementMetrics} A new instance of EngagementMetrics representing the total sum of all metrics.
     */
    total() {
        const total = new EngagementMetrics()
        Object.values(this.#rawList).forEach(metrics => {
            const data = metrics.getData()
            total.setBtnClicks(prev => prev + data.btnClicks)
            total.setOverBtnsTime(prev => prev + data.overBtnsTime)
            total.setTxtInteractionTime(prev => prev + data.txtInteractionTime)
            total.setVisibleTime(prev => prev + data.visibleTime)
        })
        return total
    }

}

export class EngagementMetrics {

    #data = {
        visibleTime: 0,
        btnClicks: 0,
        overBtnsTime: 0,
        txtInteractionTime: 0
    }

    // Default Algorithms

    /**
     * Prioritizes visibleTime calculating WEI score
      * @returns {Object} An object containing the raw data and the calculated score.
      * @returns {Object} return.rawData - The raw data used for the score calculation.
      * @returns {number} return.rawData.btnClicks - The number of button clicks.
      * @returns {number} return.rawData.overBtnsTime - The time spent over buttons (in ms).
      * @returns {number} return.rawData.visibleTime - The visible time (in ms).
      * @returns {number} return.rawData.txtInteractionTime - The time spent interacting with text (in ms).
      * @returns {number} return.score - The calculated WEI score.
     */
    PRIORITIZE_VISIBILITY = () => {
        // Prioritizes the time visible over other metrics

        const weights = {
            visibility: 0.06,
            click: 720,
            overBtn: 0.26,
            reading: 0.09
        }
        

        const score = Math.round(
            (this.#data.btnClicks * weights.click) +
            (this.#data.overBtnsTime * weights.overBtn) + 
            (this.#data.visibleTime * weights.visibility) + 
            (this.#data.txtInteractionTime * weights.reading)
        )

        return {
            rawData: this.#data,
            score
        }

    }

    /**
     * Prioritizes txtInteractionTime calculating WEI score
      * @returns {Object} An object containing the raw data and the calculated score.
      * @returns {Object} return.rawData - The raw data used for the score calculation.
      * @returns {number} return.rawData.btnClicks - The number of button clicks.
      * @returns {number} return.rawData.overBtnsTime - The time spent over buttons (in ms).
      * @returns {number} return.rawData.visibleTime - The visible time (in ms).
      * @returns {number} return.rawData.txtInteractionTime - The time spent interacting with text (in ms).
      * @returns {number} return.score - The calculated WEI score.
     */
    PRIORITIZE_READING = () => {
        // PRIORITIZEs the time reading over other metrics

        const weights = {
            visibility: 0.03,
            click: 720,
            overBtn: 0.26,
            reading: 0.2
        }
        

        const score = Math.round(
            (this.#data.btnClicks * weights.click) +
            (this.#data.overBtnsTime * weights.overBtn) + 
            (this.#data.visibleTime * weights.visibility) + 
            (this.#data.txtInteractionTime * weights.reading)
        )

        return {
            rawData: this.#data,
            score
        }

    }

    /**
     * Prioritizes btnClicks and OverBtnsTime calculating WEI score
      * @returns {Object} An object containing the raw data and the calculated score.
      * @returns {Object} return.rawData - The raw data used for the score calculation.
      * @returns {number} return.rawData.btnClicks - The number of button clicks.
      * @returns {number} return.rawData.overBtnsTime - The time spent over buttons (in ms).
      * @returns {number} return.rawData.visibleTime - The visible time (in ms).
      * @returns {number} return.rawData.txtInteractionTime - The time spent interacting with text (in ms).
      * @returns {number} return.score - The calculated WEI score.
     */
    PRIORITIZE_BUTTONS = () => {
        // PRIORITIZEs the buttons interactions over other metrics

        const weights = {
            visibility: 0.015,
            click: 1800,
            overBtn: 0.54,
            reading: 0.08
        }
        

        const score = Math.round(
            (this.#data.btnClicks * weights.click) +
            (this.#data.overBtnsTime * weights.overBtn) + 
            (this.#data.visibleTime * weights.visibility) + 
            (this.#data.txtInteractionTime * weights.reading)
        )

        return {
            rawData: this.#data,
            score
        }

    }

    constructor() {
        this.setAlgorithm(this.PRIORITIZE_READING)
    }

    /**
     * 
      * @returns {Object} An object containing the raw data and the calculated score.
      * @returns {Object} return.rawData - The raw data used for the score calculation.
      * @returns {number} return.rawData.btnClicks - The number of button clicks.
      * @returns {number} return.rawData.overBtnsTime - The time spent over buttons (in ms).
      * @returns {number} return.rawData.visibleTime - The visible time (in ms).
      * @returns {number} return.rawData.txtInteractionTime - The time spent interacting with text (in ms).
      * @returns {number} return.score - The WEI score calculated with the setted algorithm.
     */
    getResults = () => {
        return {
            rawData: this.#data,
            score: 0
        }
    }

    /**
     * Defines how score will be calculated when calling getResults() function
     * @param {function|'PRIORITIZE_BUTTONS'|'PRIORITIZE_READING'|'PRIORITIZE_VISIBILITY'} algorithm You can pass a custom function or a default function name ('PRIORITIZE_BUTTONS', 'PRIORITIZE_READING', 'PRIORITIZE_VISIBILITY')
     * @returns {EngagementMetrics} The engagement metrics itself
     */
    setAlgorithm(algorithm = () => {}) {

        let newAlgorithm = algorithm

        if (typeof newAlgorithm === 'string') {
            switch (algorithm) {
                case "PRIORITIZE_BUTTONS":
                    newAlgorithm = this.PRIORITIZE_BUTTONS
                    break 
                case "PRIORITIZE_READING":
                    newAlgorithm = this.PRIORITIZE_READING
                    break
                default:
                    newAlgorithm = this.PRIORITIZE_VISIBILITY
                    break
            }

        }

        this.getResults = newAlgorithm
        return this
    }

    /**
     * Retrieves the engagement metrics structured as a JSON-compatible object 
     * 
     * @returns {Object} An object containing engagement metrics.
     * @returns {Object} return.metrics - The engagement metrics.
     * @returns {number} return.metrics.visibleTime - The elapsed time in milliseconds that the 
     * elements were visible to the user, disregarding the time the user was idle.
     * @returns {number} return.metrics.txtInteractionTime - The elapsed time in milliseconds 
     * that the user was actively reading the text of the elements.
     * @returns {number} return.metrics.overButtonsTime - The elapsed time in milliseconds the 
     * user's cursor was over the buttons or links within the elements.
     * @returns {number} return.metrics.btnClicks - The total number of clicks made on the 
     * buttons or links within the elements.
     * 
     * @example
     * 
     * const myEngagementMetrics = new EngagementMetrics()
     * 
     * console.log(myEngagementMetrics.getRaw()) // will log something like the object bellow
     * 
     * const raw = {
     *    metrics: {
     *        visibleTime: 0,
     *        txtInteractiontime: 0,
     *        overButtonsTime: 0,
     *        btnClicks: 0
     *    }
     * }
     * 
     */
    getRaw() {
        return {
            metrics: this.#data
        }
    }

    /**
     * Retrieves the engagement metrics structured as an object 
     * 
     * @returns {Object} An object containing engagement metrics.
     * @returns {number} return.visibleTime - The elapsed time in milliseconds that the 
     * elements were visible to the user, disregarding the time the user was idle.
     * @returns {number} return.txtInteractionTime - The elapsed time in milliseconds 
     * that the user was actively reading the text of the elements.
     * @returns {number} return.overButtonsTime - The elapsed time in milliseconds the 
     * user's cursor was over the buttons or links within the elements.
     * @returns {number} return.btnClicks - The total number of clicks made on the 
     * buttons or links within the elements.
     * 
     * @example
     * 
     * const myEngagementMetrics = new EngagementMetrics()
     * 
     * console.log(myEngagementMetrics.getData()) // will log something like the object bellow
     * 
     * const data = {
     *    visibleTime: 0,
     *    txtInteractiontime: 0,
     *    overButtonsTime: 0,
     *    btnClicks: 0
     * }
     * 
     */
    getData() {
        return this.#data
    }

    /**
     * Parses raw data to EngagementMetrics structure
     * @param {Object} raw 
     * @returns 
     */
    from(raw) {

        if (!raw?.metrics) throw new Error("Spected 'Object' but recieved 'undefined' instead")
        this.#data = raw.metrics
        
        this.getResults = this.PRIORITIZE_VISIBILITY

        return this
    }

    /**
     * Sets visibleTime to the value or the callback return
     * @param {Number|function} valueOrCallback 
     * @returns 
     */
    setVisibleTime(valueOrCallback = (prev = 0) =>  prev) {
        if (typeof valueOrCallback === "function") {
            this.#data.visibleTime = valueOrCallback(this.#data.visibleTime)
        } else {
            this.#data.visibleTime = valueOrCallback
        }
        
        return this
    }

    /**
     * Sets btnClicks to the value or the callback return
     * @param {Number|function} valueOrCallback 
     * @returns 
     */
    setBtnClicks(valueOrCallback = (prev = 0) =>  prev ) {
        if (typeof valueOrCallback === "function") {
            this.#data.btnClicks = valueOrCallback(this.#data.btnClicks)
        } else {
            this.#data.btnClicks = valueOrCallback
        }
        
        return this
    }

    /**
     * Sets overBtnsTime to the value or the callback return
     * @param {Number|function} valueOrCallback 
     * @returns 
     */
    setOverBtnsTime(valueOrCallback = (prev = 0) =>  prev ) {
        if (typeof valueOrCallback === "function") {
            this.#data.overBtnsTime = valueOrCallback(this.#data.overBtnsTime)
        } else {
            this.#data.overBtnsTime = valueOrCallback
        }
        
        return this
    }

    /**
     * Sets txtInteractionTime to the value or the callback return
     * @param {Number|function} valueOrCallback 
     * @returns 
     */
    setTxtInteractionTime(valueOrCallback = (prev = 0) =>  prev ) {
        if (typeof valueOrCallback === "function") {
            this.#data.txtInteractionTime = valueOrCallback(this.#data.txtInteractionTime)
        } else {
            this.#data.txtInteractionTime = valueOrCallback
        }
        
        return this
    }

}
