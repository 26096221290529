
import { useNavigate } from "react-router-dom"
import { useContext, useEffect, useRef } from "react"
import { GlobalContext } from "../../../GlobalContext"

import Arrow from '../../../SVGR/Arrow'
import lays from '../../../assets/sitting-lays-notebook.jpg'

import LazyImage from '../../../services/LazyImage'
import GLOBAL from '../../../services/GLOBAL'

const AskForProposal = () => {

    const navigate = useNavigate()
    const contentRef = useRef()
    const {weiManager} = useContext(GlobalContext)

    const handleClick = e => {
        e.preventDefault()
        navigate('/requestForProposal')
    }
    
    useEffect(() => {
        if (contentRef?.current) {
            weiManager.see(contentRef, 'service-interest')
        }
    }, [weiManager])

    return (
        <section className="ask-for-proposal">
            <div className="container">
                
                <div className="content" ref={contentRef} >
                    <h1 className="slide-in-effect">
                        Peça já sua proposta
                    </h1>
                    <p className="slide-in-effect">
                        Quando pensamos em ESG no mercado imobiliário não é uma questão de "se", mas sim de "quando"…
                        <br /><br />
                        Você está preparado para liderar essa mudança?
                    </p>
                    <a href="/requestForProposal" className="default-button slide-in-effect" onClick={ handleClick }>
                        Quero um orçamento!
                        <Arrow className="arrow" />
                    </a>
                </div>

                <div className="image-frame">
                    
                    <LazyImage 
                        className='image'
                        alt="Lays sentada usando um notebook sobre a mesa"
                        thumbnail = { lays }
                        lightSource = { 'getImage/sitting-lays-notebook.jpg' }
                        heavySource = { 'getImage/sitting-lays-notebook.png' }
                        baseURL = { GLOBAL.BACKEND_BASE_URL }
                    />

                </div>

            </div>
        </section>
    )
}

export default AskForProposal;
