
import { createContext, useEffect, useRef, useState } from "react";

import WEIManager from "./modules/WEIManager";
import { WEIMattersList, EngagementMetrics } from "./modules/WEIManager";

import GLOBAL from './services/GLOBAL'

const GlobalContext = createContext()

const GlobalProvider = ({children}) => {

    const cookiesPermition = ( getCookie('cookies-permition') === 'allowed' )

    const WEIInterface = useRef({
        getData() {
            return fetch(`${GLOBAL.BACKEND_BASE_URL}/wei`, {
                credentials: 'include'
            })
            .then(response => {
                if (!response.ok) throw new Error('WEI request failed when pulling data.');
                return response.json();
            })
            .then((jsonRes) => {
                return JSON.parse(jsonRes?.data)
            })
            .catch(err => console.error(err))
        },
        setData(data) {
            return fetch(`${GLOBAL.BACKEND_BASE_URL}/wei`, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            })
            .then(response => {
                if (!response.ok) throw new Error('WEI request failed when pushing data.');
                return response.json();
            })
            .catch(err => {
                console.error(err);
            });
        }
    })

    const weiManagerRef = useRef(new WEIManager())

    const [areCookiesAllowed, setAreCookiesAllowed] = useState(false)
    const [mobile, setMobile] = useState(false)
    
    const [navControl, setNavControl] = useState({
        pause: false,
        openNav: () => {},
        closeNav:  () => {},
        setOpenNav: setOpenNav,
        setCloseNav: setCloseNav
    })

    const [popupsControl, setPopupsControl] = useState({
        showPopUp: undefined,
        hidePopUP: undefined,
    })

    const [mainPages] = useState([
        {
            name: 'Home',
            url: '/'
        },
        {
            name: 'Serviços',
            url: '/services'
        },
        {
            name: 'Sobre',
            url: '/about'
        },
        {
            name: 'Contato',
            url: '/contact'
        },
        {
            name: 'Solicitar proposta',
            url: '/requestForProposal'
        },
    ])

    function evaluateWEI() {
    
        const matters = weiManagerRef.current.getList() || new WEIMattersList()
        
        const score = 

            (matters.getMetrics('topic-research') || new EngagementMetrics())
                .setAlgorithm("PRIORITIZE_READING")
                .getResults()
                .score

            +

            (matters.getMetrics('company-interest') || new EngagementMetrics())
                .setAlgorithm("PRIORITIZE_READING")
                .getResults()
                .score

            +

            (matters.getMetrics('service-interest') || new EngagementMetrics())
                .setAlgorithm("PRIORITIZE_BUTTONS")
                .getResults()
                .score

        ;
        
        const normalizedScore = normalizeScore(score)
    
        const category = categorizeLead(normalizedScore)

        return {
            category,
            normalizedScore,
            categoryNames: ['cold-lead', 'warm-lead', 'hot-lead'],
            score
        }
    
        // Auxiliar Functions
        
        function normalizeScore(score, x0 = 5000, k = 0.0008) {
            return (1 / (1 + Math.exp(-k * (score - x0)))).toFixed(3)
        }
    
        function categorizeLead(score) {
            if (score >= 0.95) {
            return 2  // Hot lead
            } else if (score >= 0.45) {
            return 1  // Warm lead
            } else {
            return 0  // Cold Lead
            }
        }
    }

    function getUserFirstName() {
        return getCookie('user-first-name')
    }

    function setUserFirstName(firstName) {
        if (!firstName) {
            setCookie('user-first-name', '', { days: -1 })
            return
        }
        setCookie('user-first-name', firstName, { days: 365 })
    }

    function userNameEvent(userName) {
        if (!userName) return

        const firstName = (userName.trim().replace(/\s+/g, ' ').split(' ')[0] || '')
            .toLowerCase()
            .replace(/^\w/, (char) => char.toUpperCase());

        setUserFirstName(firstName)
    }

    function getVisitedPages() {
        const visitedPagesCookie = getCookie('visited-pages')
        if (!visitedPagesCookie) return []
    
        const splitedCookie = visitedPagesCookie.split('..')
    
        if (splitedCookie.length % 2 !== 0) { // Corrupted Cookie
            setCookie('visited-pages', '', { days: -1 })
            return []
        }
    
        const visitedPages = []
        for (let i = 0; i < splitedCookie.length - 1; i += 2) {
            visitedPages.push({
                name: splitedCookie[i],
                url: splitedCookie[i + 1]
            })
        }
    
        return visitedPages
    }
    
    function addVisitedPage(pageName, pageUrl) {
        if (!pageName || !pageUrl) throw new Error("The args must be non-empty strings.")
    
        if (pageName.includes("..") || pageUrl.includes("..")) {
            throw new Error("Invalid string. The args must not contain '..'.")
        }
    
        let visitedPagesCookie = getCookie('visited-pages') || ''
        const visitedPageString = `${pageName}..${pageUrl}`

        if (visitedPagesCookie.includes(visitedPageString)) return
    
        const visitedPagesArray = visitedPagesCookie ? visitedPagesCookie.split('..') : []
        for (let i = 0; i < visitedPagesArray.length - 1; i += 2) {
            if (visitedPagesArray[i] === pageName && visitedPagesArray[i + 1] === pageUrl) {
                return
            }
        }
    
        if (visitedPagesCookie) visitedPagesCookie += '..'
        visitedPagesCookie += visitedPageString
    
        setCookie('visited-pages', visitedPagesCookie, { days: 7 })
    }
    
    function setOpenNav(callback) {
        setNavControl(prev => {
            prev.openNav = callback
            return prev
        })
    }

    function setCloseNav(callback) {
        setNavControl(prev => {
            prev.closeNav = callback
            return prev
        })
    }


    function allowCookies() {
        setCookie('cookies-permition', 'allowed', { days: 365 })
        setAreCookiesAllowed(_ => true)
        fetch(`${GLOBAL.BACKEND_BASE_URL}/create-cookies-id`).then((res) => {
            res.json().then(id => {
                setCookie('cookies-id', id, { days: 365 })
            })
        }).catch(err => console.error(err))
    }

    function declineCookies() {
        setCookie('cookies-permition', '', { days: -1 })
        setCookie('cookies-id', '', { days: -1 })
        setAreCookiesAllowed(_ => false)
    }

    function getCookie(name) {
        const cookie = document.cookie
            .split('; ')
            .find(cookie => cookie.startsWith(`${name}=`));
    
        return cookie ? decodeURIComponent(cookie.split('=')[1]) : null;
    }    
    
    /**
     * Sets a cookie with the specified name, value, and options.
     * 
     * @param {string} name - The name of the cookie.
     * @param {string} value - The value of the cookie.
     * @param {Object} [options={}] - An optional object that can include the following properties:
     * @param {number} [options.days] - The number of days until the cookie expires. If not specified, the cookie will be a session cookie.
     * @param {string} [options.path='/'] - The path where the cookie is valid. Defaults to `/` if not specified.
     * @param {boolean} [options.secure] - If `true`, the cookie will only be sent over secure (HTTPS) connections.
     * @param {string} [options.sameSite] - Controls the cross-site request behavior of the cookie. Can be 'Strict', 'Lax', or 'None'.
     * 
     * @returns {void} - This function does not return any value.
     * 
     * @example
     * // Set a cookie that expires in 7 days
     * setCookie('user', 'Joe', { days: 7 });
     * 
     * @example
     * // Set a secure cookie with SameSite 'Strict'
     * setCookie('session', '12345', { secure: true, sameSite: 'Strict' });
     */
    function setCookie(name, value, options = {}) {

        let cookieString = `${encodeURIComponent(name)}=${encodeURIComponent(value)}`;

        if (options.days) {
            const date = new Date();
            date.setTime(date.getTime() + options.days * 24 * 60 * 60 * 1000);
            cookieString += `; expires=${date.toUTCString()}; max-age=${options.days * 24 * 60 * 60}`;
        }
    
        if (options.path) {
            cookieString += `; path=${options.path}`;
        } else {
            cookieString += `; path=/`;
        }
    
        if (options.secure) {
            cookieString += `; Secure`;
        }
    
        if (options.sameSite) {
            cookieString += `; SameSite=${options.sameSite}`;
        }
    
        document.cookie = cookieString;
    }

    /**
     * 
     * @param {number} y Y coordinate to scroll to 
     * @param {*} duration Animation duration in milliseconds
     * @param {boolean} autoStop When true the animation will stop automatically when the user manually interacts with the scroll
     * @param {function} onFinish This callback function will run when the animation finishes
     */
    const smoothScrollTo = (y, duration, autoStop=true, onFinish) => {

        let initialScroll, startTime;
        let lastScrollTo = window.scrollY

        const easeInOutQuad = (t) => {
            return t < 0.5
                ? (2 * t * t)
                : (-1 + (4 - 2 * t) * t)
        };

        const animateScroll = (currentTime) => {

            if (!startTime) startTime = performance.now()
            if (!initialScroll) initialScroll = window.scrollY

            const elapsedTime = currentTime - startTime
            const t = Math.min(elapsedTime / duration, 1)

            const scrollPosition = initialScroll + (y - initialScroll) * easeInOutQuad(t)

            if (lastScrollTo !== null && autoStop) {
                // If the user manually interacts with the scroll, the scrolling animation is stopped.
                if (Math.abs(lastScrollTo - window.scrollY) > 1.5) {
                    return
                }
            } 
            
            window.scrollTo(0, scrollPosition)
            // Updates the last requested scroll position (lastScrollToRef.current) with the new position.
            lastScrollTo = scrollPosition

            if (t < 1) {
                requestAnimationFrame(animateScroll)
            } else {
                try {
                    if (onFinish) {
                        onFinish()
                    }
                } catch (err) {
                    console.error(err)
                }
            }
        };

        requestAnimationFrame(animateScroll)
    }

    useEffect(() => {
        let timeout

        if (popupsControl?.showPopUp && !cookiesPermition ) {
            timeout = setTimeout(() => {
                popupsControl.showPopUp('cookies')
            }, 5 * 1000) // Shows after 5 seconds 
        }

        return () => {
            timeout && clearTimeout(timeout)
        }
    }, [cookiesPermition, popupsControl])

    useEffect(() => {
        const handleResize = (entries) => {
            for (let entry of entries) {
                if (entry.contentRect) {
                    const width = entry.contentRect.width
                    setMobile(width <= 768)
                }
            }
        };
      
        const resizeObserver = new ResizeObserver(handleResize)
        resizeObserver.observe(document.documentElement)
      
        return () => {
            resizeObserver.disconnect()
        }
    }, []);

    useEffect(() => {

        const weiManager = weiManagerRef.current
        weiManager.setInterface(WEIInterface.current)

        setAreCookiesAllowed(cookiesPermition)

        return () => {
            if (weiManager) weiManager.cleanupFunction()
        }

    }, [WEIInterface, cookiesPermition])

    useEffect(() => {
        areCookiesAllowed
            ? weiManagerRef.current.allowSavingData()
            : weiManagerRef.current.declineSavingData()
    }, [areCookiesAllowed])

    return (
        <GlobalContext.Provider value={{

            weiManager: weiManagerRef.current,
            areCookiesAllowed,
            allowCookies,
            declineCookies,
            getCookie,
            setCookie,
            smoothScrollTo,
            navControl,
            popupsControl,
            setPopupsControl,
            mobile,
            addVisitedPage,
            getVisitedPages,
            mainPages,
            getUserFirstName,
            setUserFirstName,
            userNameEvent,
            evaluateWEI,

        }}>
            {children}
        </GlobalContext.Provider>
    )

}

export {GlobalContext, GlobalProvider}
