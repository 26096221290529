
import LazyImage from '../../../services/LazyImage'
import GLOBAL from '../../../services/GLOBAL'

import Arrow from '../../../SVGR/Arrow'
import lays from '../../../assets/lays-ipad.png'
import { useContext, useEffect, useRef, useState } from 'react'
import { GlobalContext } from '../../../GlobalContext'

const Contact = () => {

    const {weiManager} = useContext(GlobalContext)
    const ref = useRef()

    useEffect(() => {
        if (ref?.current) {
            weiManager.see(ref, 'service-interest')
        }
    }, [weiManager])

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        message: '',
        agree: false
    })
    const { userNameEvent } = useContext(GlobalContext)
    
    const imageRef = useRef()

    const handleChange = (event, prop) => {
        setFormData( prev => ({ ...prev, [prop]: event.target.type === 'checkbox' ? event.target.checked : event.target.value }) )
    }

    const handlePhone = (e) => {

        const phone = e.target.value
        const numericPhone = removeNonNumericChar(phone)

        if (numericPhone.length > 11) {
            return;
        }

        const formattedPhone = format(numericPhone)
        
        setFormData(prev => ({
            ...prev,
            phone: formattedPhone
        }))
    
        function removeNonNumericChar(phone) {
            return phone.replace(/\D/g, '');
        }

        function format(phone) {
            if (phone.length === 0) return '';

            let result = '';
            for (let i = 0; i < phone.length; i++) {
                if (i === 0) result += '(';
                if (i === 2) result += ') ';
                if (i === 7) result += '-';
                result += phone[i];
            }
            return result;
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        userNameEvent(formData.name)

        const text = `
Nome: ${formData.name.trim()}
Email: ${formData.email.trim()}
Telefone: ${formData.phone.trim()}
Mensagem: ${formData.message.trim()}

Você concorda com as políticas de privacidade da Primodial? [${formData.agree ? 'sim' : 'não' }]
`
        fetch(`${GLOBAL.BACKEND_BASE_URL}/send-message`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ text })
        })
        .then(response => response.json() )
        .then(data => alert('Sua mensagem foi enviada!') )
        .catch(error => alert('Ocorreu um erro ao enviar sua mensagem, tente novamente mais tarde') );

    }

    useEffect(() => {
        const image = imageRef?.current
        if (!image) return

        const observer = new IntersectionObserver(([entry]) => {
            if (entry.isIntersecting) {
                if (!image.classList.contains('image-visible')) {
                    image.classList.add('image-visible')
                }
            }
        }, {
            threshold: .2
        })

        observer.observe(image)
        return () => observer.disconnect()

    }, [])

    return (
        <secttion className="contact">

            <form action="POST" onSubmit={handleSubmit} ref={ref}>
                <span>
                    <h1 className='fade-in-effect' >Vamos conversar?</h1>

                    <input
                        className='fade-in-effect' 
                        type="text"
                        name="name"
                        id="name"
                        placeholder="Nome"
                        autoComplete="name"
                        value={formData.name}
                        onChange={e => handleChange(e, 'name')}
                        required
                    />

                    <input
                        className='fade-in-effect' 
                        type="text"
                        name="tel"
                        id="tel"
                        placeholder="Whatsapp"
                        autoComplete="tel"
                        value={formData.phone}
                        onChange={handlePhone}
                        required
                    />

                    <input
                        className='fade-in-effect' 
                        type="text"
                        name="message"
                        id="message"
                        placeholder="Mensagem"
                        value={formData.message}
                        onChange={e => handleChange(e, 'message')}
                        required
                    />

                    <div className='checkbox-container fade-in-effect'>
                        <input type='checkbox' id='agreement'
                            checked={ formData.agree } 
                            onChange={ e => handleChange(e, 'agree') }
                            required
                        />
                        <label htmlFor='agreement'>Concordo com a Política de Privacidade da Primordial.</label>
                    </div>

                    <button className='fade-in-effect' type="submit" value='Enviar' name='submit' id='submit'>
                        Enviar
                        <Arrow className='arrow' bodyLength={2.7} />
                    </button>
                </span>
            </form>

            <div className="image-container">
                <div className="image-frame" ref={imageRef}>
                    <LazyImage
                        className='image'
                        thumbnail={lays}
                        lightSource='/getImage/lays-ipad-light.png'
                        heavySource='/getImage/lays-ipad.png'
                        baseURL = { GLOBAL.BACKEND_BASE_URL }
                        alt='Lays em pé segurando um iPad'
                    />
                </div>
            </div>

        </secttion>
    )
}

export default Contact;