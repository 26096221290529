
import { useContext, useEffect, useRef, useState } from "react";

import { GlobalContext } from '../../../GlobalContext'

import PlusSign from '../../../SVGR/PlusSign'
import Line from '../../../SVGR/Line'

const WhyWeWork = () => {

    const {weiManager} = useContext(GlobalContext)
    const ref = useRef()

    useEffect(() => {
        if (ref?.current) {
            weiManager.see(ref, 'topic-research')
        }
    }, [weiManager])

    const list = [
        {
            title: 'Valor',
            description: 'Buscamos entregar soluções que realmente agreguem valor ao negócio, promovendo práticas sustentáveis que fortalecem a reputação e a competitividade no mercado.'
        },
        {
            title: 'Empatia',
            description: 'Compreendemos as necessidades específicas de cada cliente e propomos estratégias personalizadas para alcançar objetivos ambientais, sociais e de governança.'
        },
        {
            title: 'Inovação',
            description: 'Aplicamos tecnologias e metodologias avançadas para otimizar processos sustentáveis, garantindo eficiência e conformidade com regulamentações ambientais.'
        }
    ]
    
    const [selectedItem, setSelectedItem] = useState(null)
    const [zIndexes, setZIndexes] = useState([])

    const itemRefs = useRef([])
    const { mobile } = useContext(GlobalContext)

    const handleSelect = (itemIndex) => {
        setSelectedItem(prev => {
            if (itemIndex === prev) {
                handleZIndexes(prev, null)
                return null
            } else {
                handleZIndexes(prev, itemIndex)
                return itemIndex
            }
        })

        function handleZIndexes(prevSelectedItem, newSelectedItem) {
            
            if (!(newSelectedItem == null)) {
                setZIndexes( prev => {
                    const newZIndexes = [...prev]
                    newZIndexes[newSelectedItem] = 2
                    return newZIndexes
                })
            }

            if (!(prevSelectedItem == null)) {
                setTimeout(() => {
                    setZIndexes( prev => {
                        const newZIndexes = [...prev]
                        newZIndexes[prevSelectedItem] = 1
                        return newZIndexes
                    })
                }, 500)
            }
        }
    }

    return (
        <section className="why-we-work" ref={ref}>
            <h1>
                Porque fazemos o que fazemos?
            </h1>
            <div className="grid-container">{ list.map( (item, index) => 
                <div
                    className={`item ${
                        selectedItem === index
                            ? 'visible'
                            : 'hidden'
                        }`
                    }
                    key={`why-we-work-item-${index}`}
                    ref={ e => { itemRefs.current[index] = e }}
                    onClick={() => handleSelect(index)}
                    style={{ zIndex: zIndexes[index] || 1 }}
                >
                    <span>
                        <h2>{item.title}</h2>
                        <div className="sign-container">{
                            selectedItem === index
                                ? <Line className="sign" />
                                : <PlusSign className="sign" />
                        }</div>
                    </span>
                    <div className="content" >
                        <p>{item.description}</p>
                    </div>
                </div>
            )}</div>
        </section>
    )
}

export default WhyWeWork;