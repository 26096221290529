
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import Arrow from '../../../SVGR/Arrow'

import LazyImage from '../../../services/LazyImage';
import GLOBAL from '../../../services/GLOBAL'

import { GlobalContext } from '../../../GlobalContext'

import X from '../../../SVGR/X'

import lays1 from '../../../assets/standing-lays-notebook-2.jpg'
import lays2 from '../../../assets/lays-ipad-5.jpg'

const Deck = () => {

    const {weiManager} = useContext(GlobalContext)

    const cardRefs = useRef([])
    const cardStylesRef = useRef(null)
    const prevSelection = useRef(null)
    const deckRef = useRef()
    const touchPosRef = useRef({x:undefined, y:undefined})

    const switchControlRef = useRef({
        timeout: null,
        pause: false
    })

    const [selectedCard, setSelectedCard] = useState(null)
    const [cardStyles, setCardsStyles] = useState({
        zIndexes: [3, 2, 1],
        classes: ['', '', '']
    })

    const [openedCard, setOpenedCard] = useState(null)
    const { mobile } = useContext(GlobalContext)

    const clearSwitchTimeout = () => {
        if (switchControlRef.current.timeout) {
            clearTimeout(switchControlRef.current.timeout)
        }
    }

    const unpauseSwitch = useCallback(() => {
        clearSwitchTimeout()

        switchControlRef.current = {
            timeout: null,
            pause: false
        }
    }, [])

    const pauseSwitch = useCallback((time) => {
        clearSwitchTimeout()
        switchControlRef.current = {
            timeout: setTimeout(unpauseSwitch, time),
            pause: true
        }
    }, [unpauseSwitch])

    const handleSelect = (cardIndex) => {
        if (switchControlRef.current.pause || selectedCard === cardIndex) return
        setSelectedCard(cardIndex)
    }

    const handleAnimationEnd = (cardIndex) => {
        setCardsStyles(prev => {
            const newStyles = {...prev}
            newStyles.classes[cardIndex] = ''
            return newStyles
        });
    }

    const closeCard = (cardIndex) => {

        const cards = cardRefs?.current
        const card = cards ? cards[cardIndex] : null
        if (!card) return

        card.classList.remove('open')
        if (selectedCard === cardIndex) {
            setOpenedCard(null)
        }
    }

    const closeCards = () => {
        const cards = cardRefs?.current
        if (cards.length === 0) return
        cards.forEach(card => {
            card.classList.remove('open')
        })
        setOpenedCard(null)
    }

    const openCard = (cardIndex) => {
        const cards = cardRefs?.current
        const card = cards ? cards[cardIndex] : null
        if (!card) return

        setOpenedCard(cardIndex)
        card.classList.add('open')
    }

    const handleClickCard = (cardIndex) => {
        const cards = cardRefs.current
        if (cards.length === 0) return
        cards.forEach((_, index) => {
            if (index !== cardIndex) {
                closeCard(index)
            }
        })
    }

    const handleTouchStart = (e) => {
        if (!mobile) return
        const { clientX: x, clientY: y } = e.touches[0];
        touchPosRef.current = {x, y}
    }

    const handleTouchMove = (e) => {

        const {x} = touchPosRef.current
        if (x == null || !mobile) return

        const {clientX} = e.touches[0]
        const deltaX = clientX - x

        if (deltaX < -50) { // Swipe left
            if (selectedCard >= cardRefs.current.length - 1) {
                handleSelect(cardRefs.current.length - 1)
            } else {
                if (selectedCard === null) {
                    handleSelect(1)
                } else {
                    handleSelect(selectedCard + 1)
                    closeCard(selectedCard)
                }
            }

            touchPosRef.current = {x: undefined, y: undefined}
        }

        if (deltaX > 50) { // Swipe right
            if (selectedCard === 0) {
                if (selectedCard === null) {
                    handleSelect(null)
                } else {
                    handleSelect(0)
                }
            } else {
                handleSelect(selectedCard - 1)
                closeCard(selectedCard)
            }
            
            touchPosRef.current = {x: undefined, y: undefined}
        }


    }

    useEffect(() => {
        if (deckRef?.current) {
            weiManager.see(deckRef, 'company-interest')
        }
    }, [weiManager])

    useEffect(() => {
        const deck = deckRef?.current
        if (!deck) return

        const observer = new IntersectionObserver(([entry]) => {
            if (entry.isIntersecting) {
                if (! deck.classList.contains('deck-visible')) deck.classList.add('deck-visible')
            }
        }, {
            threshold: .2
        })

        observer.observe(deck)
        return () => observer.disconnect()

    }, [])

    useEffect(() => {
        cardStylesRef.current = cardStyles
    }, [cardStyles])

    useEffect(() => {
        if (
            cardRefs.length === 0 ||
            !cardStylesRef.current ||
            selectedCard === null ||
            prevSelection?.current === selectedCard
        ) return

        if (mobile) return

        const styles = cardStylesRef.current
        const timeouts = []

        //
        //        0  1  2                   0  1   2
        //                                       -----
        //            -----           1          | R |
        //  1      -----R |               -----  -----
        //  2   -----C |---   --->    2   | L-----
        //  3   | L |---              3   ---| C |
        //      -----                        -----
        //            ^             
        //                                      
        if (areArraysEqual(styles.zIndexes, [3, 2, 1]) && selectedCard === 1) {
            animation1()
        }

        //        0   1   2                 0   1   2
        //                                       
        //              -----              -----
        //  1       ----- R |          1   | L -----     
        //  2   ----- C |----   --->   2   ----| C -----
        //  3   | L |----              3       ----| R |
        //      -----                              -----
        //                 ^
        //        
        if (areArraysEqual(styles.zIndexes, [3, 2, 1]) && selectedCard === 2) {
            animation6()
        }

        //        0   1   2                    0   1   2
        //      -----                               
        //  1   | L |                        -----
        //      -----    -----           1   | L -----
        //  2        ----- R |    --->   2   ----| C -----
        //  3        | C |----           3       ----| R |
        //           -----                           -----
        //                  ^
        //   
        if (areArraysEqual(styles.zIndexes, [1, 3, 2]) && selectedCard === 2) {
            animation2()
        }

        //        0   1   2                    0   1   2
        //      -----                               
        //  1   | L |                                -----
        //      -----    -----           1       ----- R |
        //  2        ----- R |    --->   2   ----- C |----
        //  3        | C |----           3   | L |----    
        //           -----                   -----        
        //        ^
        //                    
        if (areArraysEqual(styles.zIndexes, [1, 3, 2]) && selectedCard === 0) {
            animation5()
        }

        //        0   1   2                   0   1   2
        //                                          -----
        //      -----                    1           | R |
        //  1   | L -----                    -----   -----
        //  2   ----| C -----     --->   2   | L -----
        //  3       ----| R |            3   ----| C |    
        //              -----                    -----       
        //                ^
        //                     
        if (areArraysEqual(styles.zIndexes, [1, 2, 3]) && selectedCard === 1) {
            animation4()
        }

        //        0   1   2                    0   1   2
        //                                               
        //      -----                                -----
        //  1   | L -----                1       ----- R |
        //  2   ----| C -----     --->   2   ----- C |----
        //  3       ----| R |            3   | L |----    
        //              -----                -----           
        //        ^
        //                     
        if (areArraysEqual(styles.zIndexes, [1, 2, 3]) && selectedCard === 0) {
            animation7()
        }

        //       0   1   2                  0   1   2
        //             -----                         
        //  1          | R |                      -----
        //      -----  -----          1       ----- R |
        //  2   | L-----       --->   2   ----- C |----
        //  3   ---| C |              3   | L |----    
        //         -----                  -----           
        //       ^
        //                     
        if (areArraysEqual(styles.zIndexes, [2, 3, 1]) && selectedCard === 0) {
            animation5()
        }
    
        //       0   1   2                  0   1   2
        //             -----                         
        //  1          | R |              -----
        //      -----  -----          1   | L -----
        //  2   | L-----       --->   2   ----| C -----
        //  3   ---| C |              3       ----| R |
        //         -----                          -----  
        //                ^
        //                     
        if (areArraysEqual(styles.zIndexes, [2, 3, 1]) && selectedCard === 2) {
            animation3()
        }
        
        prevSelection.current = selectedCard // Save the current selection

        // Animations

        function animation1() {
            pauseSwitch(900)
            slideByLeft(0)
            slideByRight(1)
            timeouts.push(setTimeout(() => setZ([0,1,2], [1,3,2]), 500))
        }

        function animation2() {
            pauseSwitch(900)
            slideByLeft(1)
            slideByRight(2)
            timeouts.push(setTimeout(() => setZ([0,1,2], [1,2,3]), 500))
        }
        
        function animation3() {
            pauseSwitch(900)
            slideByRight(2)
            slideByLeft(1)
            timeouts.push(setTimeout(() => setZ([0,1,2], [1,2,3]), 500))
        }

        function animation4() {
            pauseSwitch(900)
            slideByRight(2)
            slideByLeft(1)
            timeouts.push(setTimeout(() => setZ([0,1,2], [2,3,1]), 500))
        }

        function animation5() {
            slideByRight(1)
            slideByLeft(0)
            timeouts.push(setTimeout(() => setZ([0,1,2], [3,2,1]), 500))
        }

        function animation6() {
            pauseSwitch(1300)
            slideByLeft(0)
            slideByRight(1)

            timeouts.push(setTimeout(() => setZ([0,1,2], [1,3,2]), 500))

            timeouts.push(setTimeout(() => {
                slideByRightDouble(2)
                timeouts.push(setTimeout(() => setZ([0,1,2], [1,2,3]), 600))
            }, 150))
        }

        function animation7() {
            pauseSwitch(1300)
            slideByRight(2)
            slideByLeft(1)

            timeouts.push(setTimeout(() => setZ([0,1,2], [2,3,1]), 500))

            timeouts.push(setTimeout(() => {
                slideByLeftDouble(0)
                timeouts.push(setTimeout(() => setZ([0,1,2], [3,2,1]), 600))
            }, 150))
        }

        // Auxiliar functions

        function areArraysEqual(arr1, arr2) { 
            return arr1.length === arr2.length && arr1.every((value, index) => value === arr2[index]);
        }

        function clearTimeouts() {
            timeouts.forEach(timeout => clearTimeout(timeout))
        }

        function setZ(cardIndexes, zIndexes) {
            setCardsStyles(prev => {
                let newStyles = {...prev}
                cardIndexes.forEach((cardIndex, i) => {
                    newStyles.zIndexes[cardIndex] = zIndexes[i]
                })
                return newStyles
            })
        }

        function slideByLeft(cardIndex) {
            setCardsStyles(prev => {
                let newStyles = {...prev}
                newStyles.classes[cardIndex] = 'slide-by-left'
                return newStyles
            })
        }

        function slideByLeftDouble(cardIndex) {
            setCardsStyles(prev => {
                let newStyles = {...prev}
                newStyles.classes[cardIndex] = 'slide-by-left-double'
                return newStyles
            })
        }
        
        function slideByRight(cardIndex) {
            setCardsStyles(prev => {
                let newStyles = {...prev}
                newStyles.classes[cardIndex] = 'slide-by-right'
                return newStyles
            })
        }

        function slideByRightDouble(cardIndex) {
            setCardsStyles(prev => {
                let newStyles = {...prev}
                newStyles.classes[cardIndex] = 'slide-by-right-double'
                return newStyles
            })
        }

        // Cleanup

        return () => {
            clearTimeouts()
        }

    }, [mobile, pauseSwitch, selectedCard])

    useEffect(() => {
        const handleClick = (e) => {
            const cards = cardRefs?.current;
            if (!cards) return
        
            const isInsideCard = e.target.closest('.card') !== null
        
            if (!isInsideCard) {
                closeCards()
            }
        }
        document.addEventListener('mouseup', handleClick)
        document.addEventListener('touchend', handleClick)
        return () => {
            document.removeEventListener('mouseup', handleClick)
            document.removeEventListener('touchend', handleClick)
            clearSwitchTimeout()
        }
    }, [])

    return (
        <section className="deck" id='deck' ref={deckRef} >
            <div className='cards-container'
                style={ mobile ? {
                    transform: `translateX(-${
                        selectedCard === 0
                            ? 0
                            : selectedCard === 1
                                ? 25
                                : selectedCard === 2 
                                    ? 50
                                    : 0
                }em`} : null }
                onTouchMove={handleTouchMove}
                onTouchStart={handleTouchStart}
            >
                <div
                    className={`card left ${cardStyles.classes[0]}`}
                    ref={ e => cardRefs.current[0] = e }
                    onClick={() => {
                        handleSelect(0)
                        handleClickCard(0)
                    }}
                    style={{
                        zIndex: cardStyles.zIndexes[0],
                        filter: selectedCard === 0 || selectedCard === null ? 'none' : 'brightness(.9)'
                    }}
                    onAnimationEnd={() => handleAnimationEnd(0)}
                >{
                    openedCard === 0

                    ?
                        <>
                            <span>

                                <p>A verdade é que tudo o que nos é imposto por obrigação gera repulsa. E isso nos afasta de questionar o "porquê" daquilo ser relevante em nossas vidas. Compreender a diferença entre ser e parecer vai muito além do cumprimento de normas.</p>

                                <p>Não queremos forçar coordenadores e gerentes de médias empresas a se submeterem ao sistema apenas por obrigação. Em vez disso, buscamos orientar esses líderes a compreender por que "ser" uma empresa modelo em gestão ambiental é uma escolha inteligente. Cremos que, assim, conseguiremos engajá-los e torná-los verdadeiramente comprometidos com a causa ambiental.</p>
                            
                                <p>Temos uma maneira própria de agir, sempre com empatia e atenção a cada empresa que ajudamos. Tratamos cada projeto com o mesmo cuidado que teríamos com um filho, abraçando a causa ambiental como parte da nossa missão.</p>
                            
                                <p>Transformar uma organização em um modelo de gestão ambiental é o jeito Primordial de ser.</p>
                            </span>
                            <button className="x-container default-button" onClick={() => closeCard(0)} >
                                <X className='x' />
                            </button>
                        </>
                    :   <>
                            <span>
                                <h1>Preencher relatórios é suficiente para entender a importância da causa ambiental?</h1>
                                <p>Ou isso é apenas se ajustar às regras impostas por exigências legislativas?</p>
                            </span>

                            <button className='btn default-button' onClick={(e) =>  openCard(0)}>
                                Veja além
                                <Arrow bodyLength={2.7} className='arrow' />
                            </button>
                        </>
                } </div>

                <div
                    className={`card center ${
                        cardStyles.classes[1]
                    } ${
                        selectedCard !== 1
                            ? 'image-visible'
                            : 'image-hidden'
                    }`}
                    ref={ e => cardRefs.current[1] = e }
                    onClick={() => {
                        handleSelect(1)
                        handleClickCard(1)
                    }}
                    style={{
                        zIndex: cardStyles.zIndexes[1],
                        filter: selectedCard === 1 ? 'none' : 'brightness(.9)'
                    }}
                    onAnimationEnd={() => handleAnimationEnd(1)}
                >

                    { mobile
                        ? null
                        :   <div className='image-frame'>
                                <LazyImage
                                    className='image'
                                    thumbnail={lays1}
                                    lightSource='/getImage/standing-lays-notebook-2.jpg'
                                    heavySource='/getImage/standing-lays-notebook-2.png'
                                    baseURL = { GLOBAL.BACKEND_BASE_URL }
                                    alt='Lays em pé usando um notebook sobe uma mesa'
                                />
                            </div>
                    }
                    {
                        openedCard === 1

                        ?   <>
                                <span>

                                    <p>Atuando há mais de 11 anos como Engenheira Ambiental e Sanitarista, formada pela PUC Campinas, tive a oportunidade de unir a teoria à prática, alcançando marcos significativos em minha carreira.</p>

                                    <p>Iniciei minha jornada profissional almejando trabalhar em uma multinacional e realizei esse sonho ao ingressar, em 2014, no programa de estágio da Cervejaria Ambev em Jaguariúna, onde foquei na Gestão Ambiental da cervejaria, liderei iniciativas de Gestão de Resíduos, Licenciamento Ambiental, Treinamentos e Gestão de Indicadores Ambientais, culminando na honra de receber o prêmio Pilar Gestão de Destaque.</p>
                                
                                    <p>Em 2019 fundei a Primordial Consultoria Ambiental. Nosso objetivo é oferecer uma gama completa de serviços personalizados para empresas e indústrias, focados em gestão ambiental estratégica, elaboração de estudos ambientais, licenciamento e gestão de resíduos. Estamos comprometidos em auxiliar nossos clientes a alcançar os mais altos padrões ambientais, tornando suas empresas exemplos de sustentabilidade.</p>
                                </span>
                                <button className="x-container default-button" onClick={() => closeCard(1)} >
                                    <X className='x' />
                                </button>
                            </>

                        :   <>
                                <span>
                                    <h1>Quem é a pessoa por trás da Primordial e como sua trajetória moldou a consultoria?</h1>
                                    <p>Conheça a fundadora, sua jornada profissional, os desafios que superou e a visão que a levou a criar a Primordial Consultoria Ambiental.</p>
                                    
                                </span>
                                
                                <button className='btn default-button' onClick={(e) =>  openCard(1)}>
                                    Descubra
                                    <Arrow bodyLength={2.7} className='arrow' />
                                </button>
                            </>
                    }
                </div>

                <div
                    className={`card right ${
                        cardStyles.classes[2]
                    } ${
                        selectedCard !== 2
                            ? 'image-visible'
                            : 'image-hidden'
                    }`}
                    ref={ e => cardRefs.current[2] = e }
                    onClick={() => {
                        handleSelect(2)
                        handleClickCard(2)
                    }}
                    style={{
                        zIndex: cardStyles.zIndexes[2],
                        filter: selectedCard === 2 ? 'none' : 'brightness(.9)'
                    }}
                    onAnimationEnd={() => handleAnimationEnd(2)}
                >
                    { mobile
                        ? null
                        :   <div className='image-frame'>
                                <LazyImage
                                    className='image'
                                    thumbnail={lays2}
                                    lightSource='/getImage/lays-ipad-5.jpg'
                                    heavySource='/getImage/lays-ipad-5.png'
                                    baseURL = { GLOBAL.BACKEND_BASE_URL }
                                    alt='Lays em pé segurando um iPad'
                                />
                            </div>
                    }
                    

                    {
                        openedCard === 2

                        ?   <>  
                                <span>
                                    <p>O mercado imobiliário, nos últimos anos, teve um grande desafio: prosperar de forma sustentável. Construtoras, incorporadoras e investidores querem desenvolver projetos inovadores e lucrativos, mas encontram um obstáculo pelo caminho.</p>

                                    <p>Durante anos, o setor tratou a sustentabilidade apenas como uma obrigação, para evitar multas. Isso criou um cenário em que muitas empresas fazem ESG apenas para cumprir tabela. Investidores, clientes e órgãos reguladores cobram uma postura diferente. </p>
                                
                                    <p>Foi observando esse cenário que a Primordial entendeu que ESG não é uma tendência, mas um diferencial estratégico capaz de transformar empreendimentos.</p>

                                    <p>Nosso trabalho não é apenas evitar penalizações. Nós geramos valor. Mostramos que sustentabilidade e lucratividade podem caminhar juntas. Criamos sinergia entre meio ambiente, sociedade e governança. Não somos tendência. Somos transformação.</p>
                                </span>
                                <button className="x-container default-button" onClick={() => closeCard(2)} >
                                    <X className='x' />
                                </button>
                            </>
                        :   <>
                                <span>
                                    <h1>Por que a Primordial decidiu atuar com ESG no setor imobiliário?</h1>
                                    <p>Mais que uma tendência, o ESG é uma necessidade real - um diferencial que gera valor.</p>
                                </span>

                                <button className='btn default-button' onClick={(e) =>  openCard(2)}>
                                    Saiba mais
                                    <Arrow bodyLength={2.7} className='arrow' />
                                </button>
                            </>
                    }
                </div>

            </div>

            { mobile
                ?   <div className='selector-container'>
                        <span>
                            <button
                                className='prev btn'
                                onClick={() => {
                                    if (switchControlRef.current.pause) return

                                    if (selectedCard !== 0) {
                                        if (selectedCard === null) {
                                            handleSelect(0)
                                        } else {
                                            handleSelect(selectedCard - 1)
                                            pauseSwitch(500)
                                        }
                                    }
                                }}
                            >
                                <Arrow className='arrow' />
                            </button>
                            <button
                                className='next btn'
                                onClick={() => {
                                    if (switchControlRef.current.pause) return
                                    
                                    if (selectedCard !== cardRefs.current.length - 1) {
                                        handleSelect(selectedCard + 1)
                                        pauseSwitch(500)
                                    }
                                }}
                            >
                                <Arrow className='arrow' />
                            </button>
                        </span>
                    </div>
                : null
            }

        </section>
    )
}

export default Deck;