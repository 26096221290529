
import LazyImage from '../../../services/LazyImage'
import GLOBAL from '../../../services/GLOBAL'

import city from '../../../assets/city-aerial-photo-2.jpg'
import { useContext, useEffect, useRef } from 'react'
import { GlobalContext } from '../../../GlobalContext'

const WeUseTech = () => {

    const {weiManager} = useContext(GlobalContext)
    const ref = useRef()

    useEffect(() => {
        if (ref?.current) {
            weiManager.see(ref, 'company-interest')
        }
    }, [weiManager])

    return (
        <section className="we-use-tech">
            
            <div className="image-frame">
                <LazyImage
                    className='image'
                    thumbnail={city}
                    lightSource='/getImage/city-aerial-photo-2.jpg'
                    heavySource='/getImage/city-aerial-photo-2.png'
                    baseURL = { GLOBAL.BACKEND_BASE_URL }
                    alt='Cidade vista de cima'
                />
            </div>
            
            <div className="container">
                <div className="content" ref={ref}>
                    <span>
                        <h1 className='slide-in-effect' >Usamos tecnologia ao nosso favor!</h1>
                        <p className='slide-in-effect' >
                            Utilizamos tecnologia de ponta, como drones, para aprimorar a qualidade e a precisão&nbsp;
                            de nossos estudos ambientais. O uso de drones nos permite realizar levantamentos aéreos&nbsp;
                            detalhados, capturando imagens de alta resolução e dados mais precisos. Essa abordagem nos&nbsp;
                            fornece uma visão abrangente das áreas, superando as expectativas de nossos clientes.
                        </p>
                    </span>
                </div>
            </div>

        </section>
    )
}

export default WeUseTech;