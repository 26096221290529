
import { useContext, useEffect, useRef, useState } from "react";

import TypingEffect from "../../../modules/animators/TypingEffect";

import PlusSign from '../../../SVGR/PlusSign'
import Line from '../../../SVGR/Line'
import Triangle from "../../../SVGR/Triangle";
import { GlobalContext } from "../../../GlobalContext";

const IsESGForMe = () => {

    const doubts = [
        { 
            title: 'Como funciona?',
            description: 'ESG integra práticas ambientais, sociais e de governança para tornar empresas mais responsáveis e sustentáveis.'
        },
        { 
            title: 'Quais as vantagens?',
            description: 'Reduz custos, melhora a reputação e atrai investidores interessados em sustentabilidade.'
        },
        { 
            title: 'Qual é o ROI?',
            description: 'Entre os retornos estão: maior eficiência, crescimento sustentável e maior competitividade no mercado.'
        }
    ];

    const descriptionRefs = useRef([])
    const contentRef = useRef()

    const [selectedItem, setSelectedItems] = useState([])

    const {weiManager} = useContext(GlobalContext)

    const toggleSelection = (itemKey) => {
        setSelectedItems(prev => {
            if (itemKey == null) return prev;

            if (prev === itemKey) {
                return undefined
            } 

            return itemKey

        })
    }

    useEffect(() => {
        // This will make descriptions visible after finishing the accordion-out animation

        const timeout = setTimeout(() => {
            descriptionRefs.current.forEach( el => {
                el.style.visibility = 'visible'
            })
        }, 900)

        return () => {
            clearTimeout(timeout)
        }
    }, [])

    useEffect(() => {
        if (contentRef?.current) {
                weiManager.see(contentRef, 'topic-research')
        }
    }, [weiManager])

    return (
        <section className="is-esg-for-me"><div className="container">

            <div className="header slide-in-effect">
                <TypingEffect speed={.9} >👉 "ESG é pra mim?"</TypingEffect>
                <Triangle className="triangle" />
            </div>

            <div className="content slide-in-effect" ref={contentRef} >{
                doubts.map((item, index) => (
                    <div className="item-container" key={'is-esg-for-me-item-'+index} >

                        <div
                            className={`item ${ selectedItem === index ? '' : 'unselected' }`}
                            style={ selectedItem === index ? { zIndex: 200 } : null }
                        >
                            <div className="title">
                                <span>
                                    <h2>{item.title}</h2>

                                    <div className="btn"
                                        onClick={ () => toggleSelection(index) }
                                        style={ selectedItem === index ? { backgroundColor: '#555' } : {} }
                                    >
                                        <PlusSign
                                            className={`sign ${ selectedItem === index ? 'hide' : '' }`}
                                        />
                                        <Line
                                            className={`sign ${ selectedItem === index ? '' : 'hide' }`}
                                        />
                                    </div>
                                </span>
                            </div>

                        </div>
                        
                        <div
                            className={`description-container ${ selectedItem === index ? '' : 'hidden' }`}
                            style={{
                                zIndex: selectedItem === index ? 150: null,
                                visibility: 'hidden' // This will avoid accordion-out effect to be visible in component mounting
                            }}
                        >
                            <div
                                ref={ (el) => {
                                    if (el) descriptionRefs.current[index] = el
                                }}
                                className={`description ${
                                    selectedItem === index ? 'visible' : 'hidden'
                                }`}
                            >
                                {item.description}
                            </div>
                        </div>

                    </div>
                ))
            }</div>

        </div></section>
    )
}

export default IsESGForMe;