
import './styles.css'
import React, { useContext, useEffect, useState } from 'react'
import GLOBAL from '../../services/GLOBAL.js'

import contact_img from '../../assets/contact-page.jpg'

// This image isn't being used anymore because the client requested to remove the section that was using it
// import map from '../../assets/map-background.jpg'

import LazyImage from '../../services/LazyImage.jsx'
import Nav from '../Nav/index.jsx'
import Footer from '../Footer/index.jsx'
import { GlobalContext } from '../../GlobalContext.jsx'

export default function Contact() {

    const [contactInfo, setContactInfo] = useState({address: '', email: '', phone_number: ''})
    const { userNameEvent } = useContext(GlobalContext)

    useEffect(() => {
        window.scrollTo(0, 0);

        const fetchContactInfo = async () => {
            try {
                const { contact_info } = await GLOBAL.CONTENT;
                setContactInfo(contact_info);
            } catch (error) {
                console.error('Failed to fetch Contact Info data:', error);
            }
        };

        fetchContactInfo();
    }, [])

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        message: '',
        agree: false
    })

    const handleChange = (event, prop) => {
        setFormData( prev => ({ ...prev, [prop]: event.target.type === 'checkbox' ? event.target.checked : event.target.value }) )
    }

    const handlePhone = (e) => {

        const phone = e.target.value
        const numericPhone = removeNonNumericChar(phone)

        if (numericPhone.length > 11) {
            return;
        }

        const formattedPhone = format(numericPhone)
        
        setFormData(prev => ({
            ...prev,
            phone: formattedPhone
        }))
    
        function removeNonNumericChar(phone) {
            return phone.replace(/\D/g, '');
        }

        function format(phone) {
            if (phone.length === 0) return '';

            let result = '';
            for (let i = 0; i < phone.length; i++) {
                if (i === 0) result += '(';
                if (i === 2) result += ') ';
                if (i === 7) result += '-';
                result += phone[i];
            }
            return result;
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault()

        userNameEvent(formData.name)

        const text = `
Nome: ${formData.name.trim()}
Email: ${formData.email.trim()}
Telefone: ${formData.phone.trim()}
Mensagem: ${formData.message.trim()}

Você concorda com as políticas de privacidade da Primodial? [${formData.agree ? 'sim' : 'não' }]
`
        
        fetch(`${GLOBAL.BACKEND_BASE_URL}/send-message`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ text })
        })
        .then(response => response.json() )
        .then(data => alert('Sua mensagem foi enviada!') )
        .catch(error => alert('Ocorreu um erro ao enviar sua mensagem, tente novamente mais tarde') );

    }

    return (
        <div className='contact-container'>
            <Nav />
            <div className='hero-container'>
                <LazyImage 
                    className='image'
                    alt='Notebook sobre escrivaninha em frente às persianas'
                    thumbnail = { contact_img }
                    lightSource = { 'getImage/contact-page.jpg' }
                    heavySource = { 'getImage/contact-page.png' }
                    baseURL = { GLOBAL.BACKEND_BASE_URL }
                />

                <div className='content-container'>
                    <h1 className='title'>Contato</h1>
                    <p className='content'>
                        <strong>Endereço:</strong> { contactInfo.address } <br/>
                        <strong>E-mail:</strong> { contactInfo.email } <br/>
                        <strong>Telefone:</strong> { contactInfo.phone_number }
                    </p>
                </div>

            </div>

            <div className='form-container'>
                <form onSubmit={ handleSubmit }>
                    <h1 className='title'>Ei! Como podemos te ajudar?</h1>

                    <div className='grid-container'>
                        <input type='text' autoComplete='name' id='name'
                            className='name'
                            value={ formData.name }
                            onChange={ e => handleChange(e, 'name') }
                            placeholder='Seu nome aqui'
                            required
                        />
                            
                        <input type='text' autoComplete='email' id='email'
                            className='email'
                            value={ formData.email }
                            onChange={ e => handleChange(e, 'email') }
                            placeholder='E-mail'
                            required
                        />
                        
                        <input
                            className='phone'
                            type="tel"
                            id="phone"
                            name="phone"
                            value={ formData.phone }
                            onChange={ handlePhone }
                            placeholder="DDD + Whatsapp"
                            pattern="\(\d{2}\) \d{5}-\d{4}"
                            required
                        />

                        
                        <textarea id='message'
                            className='message'
                            value={ formData.message }
                            onChange={ e => handleChange(e, 'message') }
                            placeholder='Mensagem...'
                            required
                        />
                    </div>
                    
                    <div className='checkbox-container' >
                        <input type='checkbox' id='agreement'
                            checked={ formData.agree } 
                            onChange={ e => handleChange(e, 'agree') }
                            required
                        />
                        <label htmlFor='agreement'>Concordo com a Política de Privacidade da Primordial.</label>
                    </div>
                    

                    <input type='submit' className='submit default-button' value='Enviar Mensagem' />

                </form>
            </div>

            { /* Client requested to remove this section
            <div className='map-container'>
                <LazyImage 
                    className='image'
                    alt='Mapa grande sobre mesa com alfinete inserido'
                    thumbnail = { map }
                    lightSource = { 'getImage/map-background.jpg' }
                    heavySource = { 'getImage/map-background.png' }
                    baseURL = { GLOBAL.BACKEND_BASE_URL }
                />
            </div>
            */}

            <Footer />
        </div>
    )
}