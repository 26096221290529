
import LazyImage from '../../../services/LazyImage'
import GLOBAL from '../../../services/GLOBAL'

import { GlobalContext } from '../../../GlobalContext'

import X from '../../../SVGR/X'

import lays from '../../../assets/lays-ipad-2.png'
import { useContext, useEffect, useState } from 'react'


const RequestMeeting = ({ hidePopup }) => {

    const { mobile } = useContext(GlobalContext)
    const [whatsappLink, setWhatsAppLink] = useState(null)
    
    useEffect(() => {
        GLOBAL.WHATSAPP_LINK.then(link => setWhatsAppLink(link))
    }, [])

    return (
        <div className="request-meeting">

            <span>

                <h1>
                    Oi, eu sou a Lays! 😄
                </h1>

                <p>
                    Quer marcar uma reunião para criarmos uma proposta personalizada?
                </p>

                <p>
                    Ou posso tirar suas dúvidas antes. O que você acha?
                </p>

                <div className='cta-container'>
                    <a
                        href={`${whatsappLink}${encodeURIComponent('Olá, Quero marcar uma reunião!')}`}
                        target='_blank'
                        rel='noreferrer nofollow noopener'
                        className='meeting default-button'
                    >
                        {
                            mobile
                                ? 'Agende uma reunião!'
                                : 'Reunião'
                        }
                    </a>

                    <a
                        href={`${whatsappLink}${encodeURIComponent('Olá, pode me tirar algumas dúvidas?')}`}
                        target='_blank'
                        rel='noreferrer nofollow noopener'
                        className='doubts default-button'
                    >
                        {
                            mobile
                                ? 'Tem dúvidas?'
                                : 'Dúvidas?'
                        }
                    </a>
                </div>

            </span>
            
            <div className="image-container">
                <LazyImage
                    className='image'
                    thumbnail={lays}
                    lightSource='/getImage/lays-ipad-2-light.png'
                    heavySource='/getImage/lays-ipad-2.png'
                    baseURL = { GLOBAL.BACKEND_BASE_URL }
                    alt='Lays em pé segurando um Ipad'
                />
            </div>

            <div className='x-container default-button'
                onClick={ e => {
                    e.preventDefault()
                    hidePopup(e)
                }}
            >
                <X className='x' />
            </div>
        </div>
    )
}

export default RequestMeeting;