
import { useContext, useEffect, useState } from 'react'
import GLOBAL from '../../../services/GLOBAL'

import X from '../../../SVGR/X'
import { GlobalContext } from '../../../GlobalContext'
import { useNavigate } from 'react-router-dom'

const ServiceOffering = ({ hidePopup }) => {

    const [engagementResults, setEngagementResults] = useState(null) 
    const { evaluateWEI } = useContext(GlobalContext)

    useEffect(() => {
        const evaluation = evaluateWEI()
        setEngagementResults(evaluation)
    }, [evaluateWEI])

    return (
        <div className="service-offering">
            { engagementResults?.category === 0
                ? <ColdLeadContent hidePopup={hidePopup} />
                : engagementResults?.category === 1
                    ? <WarmLeadContent hidePopup={hidePopup} />
                    : <HotLeadContent hidePopup={hidePopup} />
            }
        </div>
    )
}

const ColdLeadContent = ({ hidePopup }) => {

    const handleClick = (e) => {
        e.preventDefault()
        window.open('https://www.linkedin.com/pulse/o-que-est%C3%A1-acontecendo-mercado-imobili%C3%A1rio-ningu%C3%A9m-te-andrade-jsymc/?trackingId=yOFdd0ik%2Fpim7l08Ik91Tw%3D%3D', '_blank')
    }

    return (
        <>
            <h1 className='cold-lead'>Sabia que os bancos estão priorizando projetos ESG? 🤔</h1>
            <p className='cold-lead'>"Até 2023, contribuímos com aproximadamente R$ 90 bilhões em dívidas verdes, sociais, sustentáveis e vinculadas a metas ESG." -&nbsp;
                <a
                    className='cold-lead'
                    title='ESG - Produtos Sustentáveis e de Impacto'
                    href="https://www.btgpactual.com/esg-e-impact-investing/produtos-sustentaveis-e-de-impacto"
                    rel='noreferer nofollow noopener'
                >
                    BTG Pactual
                </a> 
            </p>
            <p className='last cold-lead'>Você precisa entender o que está acontecendo!</p>
            
            <a className="cta cold-lead default-button"
                href={'https://www.linkedin.com/pulse/o-que-est%C3%A1-acontecendo-mercado-imobili%C3%A1rio-ningu%C3%A9m-te-andrade-jsymc/?trackingId=yOFdd0ik%2Fpim7l08Ik91Tw%3D%3D'}
                rel='noopener nofollow'
                onClick={ handleClick }
            >
                Saiba mais no LinkedIn
            </a>

            <div className='cold-lead x-container default-button' onClick={ hidePopup }>
                <X className='x' />
            </div>
        </>
    ) 
}

const WarmLeadContent = ({ hidePopup }) => {

    const [formData, setFormData] = useState({
        name: ''
    })

    const handleChange = (event, prop) => {
        setFormData( prev => ({ ...prev, [prop]: event.target.type === 'checkbox' ? event.target.checked : event.target.value }) )
    }

    const handlePhone = (e) => {

        const phone = e.target.value
        const numericPhone = removeNonNumericChar(phone)

        if (numericPhone.length > 11) {
            return;
        }

        const formattedPhone = format(numericPhone)
        
        setFormData(prev => ({
            ...prev,
            phone: formattedPhone
        }))
    
        function removeNonNumericChar(phone) {
            return phone.replace(/\D/g, '');
        }

        function format(phone) {
            if (phone.length === 0) return '';

            let result = '';
            for (let i = 0; i < phone.length; i++) {
                if (i === 0) result += '(';
                if (i === 2) result += ') ';
                if (i === 7) result += '-';
                result += phone[i];
            }
            return result;
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault()
         
        if (!formData.name || !formData.phone || !formData.agree) alert('É preciso preencher todos os campos para enviar')

        fetch(`${GLOBAL.BACKEND_BASE_URL}/lead`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(formData)
        })
        .then(response => response.json() )
        .then(data => alert('Entraremos em contato, Obrigada!') )
        .catch(error => alert('Não conseguimos enviar os dados, tente novamente mais tarde!'));
        
        hidePopup()
        window.open(GLOBAL.WHATSAPP_LINK, '_blank')
    }

    return (
        <>
            <h1>Vi que você precisa de ajuda para implementar a gestão ambiental na sua contrutora/incorporadora. Posso ajudar? 😊</h1>
            <form action="POST" onSubmit={ handleSubmit }>
                
                <input type='text' autoComplete='name' id='name'
                    className='name'
                    value={ formData.name }
                    onChange={ e => handleChange(e, 'name') }
                    placeholder='Nome:'
                    required
                />

                <input
                    className='phone'
                    type="tel"
                    id="phone"
                    name="phone"
                    value={ formData.phone }
                    onChange={ handlePhone }
                    placeholder="Whatsapp:"
                    pattern="\(\d{2}\) \d{5}-\d{4}"
                    required
                />
                    
                <div className='checkbox-container' >
                    <input type='checkbox' id='agreement'
                        checked={ formData.agree } 
                        onChange={ e => handleChange(e, 'agree') }
                        required
                    />
                    <label htmlFor='agreement'>Concordo com a Política de Privacidade da Primordial.</label>
                </div>

                <input type="submit" value='Whatsapp' className='submit default-button' />
            </form>
            <div className='x-container default-button' onClick={ hidePopup }>
                <X className='x' />
            </div>
        </>
    )
}

const HotLeadContent = ({ hidePopup }) => {
    
    const navigate = useNavigate()

    const handleCLick = (e) => {
        e.preventDefault()
        navigate('/requestForProposal')
    }

    return (
        <>
            <h1 className='hot-lead'>ESG não é o fututo,  ESG <br/>é o Presente! 😄</h1>
            
            <p className='first hot-lead'>Seus concorrentes já estão aumentando o valor dos seus imóveis e conseguindo maiores investimentos com a aplicação do ESG.</p>
            <p className='last hot-lead'>Não perca tempo, peça uma proposta agora mesmo!</p>

            <a
                className='hot-lead cta default-button'
                onClick={handleCLick}
                rel='noopener nofollow'
                href='/requestForProposal'
            >
                Pedir Proposta
            </a>

            <div className='hot-lead x-container default-button' onClick={ hidePopup }>
                <X className='x' />
            </div>
        </>
    )
}

export default ServiceOffering;