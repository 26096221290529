
import './main.css'

import AcceptCookies from './components/AcceptCookies'
import { GlobalContext } from '../../GlobalContext'
import { useCallback, useContext, useEffect, useState } from 'react'
import ServiceOffering from './components/ServiceOffering'
import WelcomeBack from './components/WelcomeBack'
import RequestMeeting from './components/RequestMeeting'

const PopUpDisplay = () => {

    const { setPopupsControl, getCookie, setCookie } = useContext(GlobalContext)

    const [showControl, setShowControl] = useState({
        cookies: false,
        serviceOffering: false,
        welcomeBack: false,
        requestMeeting: false,
    })

    const [visible, setVisible] = useState(false)
    const [popupsRow, setPopupsRow] = useState([])

    const [history] = useState({
        _getHistoryCookie() {

            let cookie = getCookie('popup-calling-history')
            if (cookie == null) {
                setCookie('popup-calling-history', '', { days: 365} )
                return ''
            }

            let splitedCookie = (cookie && cookie.split('.')) || []
            splitedCookie = splitedCookie.filter(e => e !== '') // Remove empty strings

            if (splitedCookie.length % 2 !== 0) { // Currupted Cookie
                setCookie('popup-calling-history', '', { days: 365} )
                splitedCookie = []
            }

            cookie = splitedCookie.join('.')
            return cookie
        },
        callingEvent(popupName) {

            let callings = history.all()
            const callingCount = history.count(popupName)

            if (callingCount >= 20) { // Removes the older calling when reaches 20 callings
                const olderCalling = history.olderCalling(popupName)
                
                const foundCalling = callings.find(calling => 
                    calling.popupName === olderCalling.popupName
                    && calling.date.getTime() === olderCalling.date.getTime()
                )

                const foundIndex = callings.indexOf(foundCalling)
                callings.splice(foundIndex, 1)
            }

            callings.push({
                popupName,
                date: new Date()
            })

            let cookie = ''
            callings.forEach((calling, index) => cookie += `${index ? '.' : ''}${calling.popupName}.${calling.date.getTime()}`)
            
            const encodedCookie = encodeURI(cookie)
            setCookie('popup-calling-history', encodedCookie, { days: 365 })
        },
        all() {
            let cookie = history._getHistoryCookie()
            let splitedCookie = cookie.split('.')

            let callingHistory = []

            let calling = {}
            splitedCookie.forEach((item, index) => {

                if ((index + 1) % 2 === 0) {
                    if (!Number.isNaN(item)) {
                        calling.date = new Date(Math.round(item))
                    } else {
                        calling.date = new Date(Date.now() - 60 * 60 * 24) // If date is invalid sets the date to 1 day before
                    }
                } else {
                    calling.popupName = item
                }

                if (calling?.popupName && calling?.date) {
                    callingHistory.push({...calling})
                    calling = {}
                }
            })

            return callingHistory
        },
        olderCalling(popupName) {
            const callingHistory = history.all()

            const callings = callingHistory.filter(calling => calling.popupName === popupName)
            if (callings.length === 0) {
                return null
            }

            const callingTimes = callings.map(calling => calling.date.getTime())
            const olderCallingIndex = callingTimes.indexOf(Math.min(...callingTimes))

            return callings[olderCallingIndex]
        },
        lastCalling(popupName) {
            const callingHistory = history.all()

            const callings = callingHistory.filter(calling => calling.popupName === popupName)
            if (callings.length === 0) {
                return null
            }

            const callingTimes = callings.map(calling => calling.date.getTime())
            const lastCallingIndex = callingTimes.indexOf(Math.max(...callingTimes))

            return callings[lastCallingIndex]
        },
        count(popupName) {
            const callingHistory = history.all()

            const callings = callingHistory.filter(calling => calling.popupName === popupName)
            if (callings.length === 0) {
                return 0
            }

            return callings.length
        }
    })

    const showPopUp = useCallback((popupName) => {
        setPopupsRow(prev => {
            let newPopupsRow = [popupName]

            prev.forEach(name => {
                if (name !== popupName) newPopupsRow.push(name)
            })

            history.callingEvent(popupName)
            return newPopupsRow
        })
    }, [history])

    const hidePopUp = (popupName) => {
        setPopupsRow(prev => {
            let newPopupsRow = prev.filter(name => name !== popupName)
            return newPopupsRow
        })
    }

    useEffect(() => {

        const timeouts = []

        timeouts[0] = setTimeout(offerService, 15 * 1000) // Runs after 15 seconds
        timeouts[1] = setTimeout(greet, 300)

        const handleExitIntent = (event) => {
            if (!event.relatedTarget && event.clientY <= 0) {
                requestMeeting();
            }
        }
        document.addEventListener('mouseout', handleExitIntent)

        function offerService() {
            const lastCalling = history.lastCalling('serviceOffering')
            const lastCallingTime = lastCalling?.date?.getTime() || 0
            if (Date.now() - lastCallingTime >= 4 * 60 * 60 * 1000) { // Avoid showing again if shown within 4 hours
                showPopUp('serviceOffering')
            }
        }

        function greet() {

            const callingCount = history.count('welcomeBack')
            if (callingCount === 0) {
                history.callingEvent('welcomeBack')
                return
            }

            const isTheSecondAccess = callingCount === 1
            let timeOffset = isTheSecondAccess
                ? 1 * 60 * 60 * 1000 // 1 hour
                : 2 * 24 * 60 * 60 * 1000 // 2 days

            const lastCalling = history.lastCalling('welcomeBack')
            const lastCallingTime = lastCalling?.date?.getTime() || 0

            if (Date.now() - lastCallingTime >= timeOffset) { 
                showPopUp('welcomeBack')
            }
        }

        function requestMeeting() {
            const lastCalling = history.lastCalling('requestMeeting')
            const lastCallingTime = lastCalling?.date?.getTime() || 0
            if (Date.now() - lastCallingTime >= 5 * 60 * 60 * 1000) { // Avoid showing again if shown within 5 minutes
                showPopUp('requestMeeting')
            }
        }

        return () => {
            timeouts.forEach(timeout => clearTimeout(timeout))
            document.removeEventListener('mouseout', handleExitIntent)
        }

    }, [history, showPopUp])

    useEffect(() => {
        setShowControl(prev => {

            const newControl = {...prev}

            Object.keys(newControl).forEach(key => {
                if (popupsRow[0] !== key) {
                    newControl[key] = false
                } else {
                    newControl[key] = true
                }
            })
            
            return newControl

        })
    }, [popupsRow])

    useEffect(() => {
        if (Object.values(showControl).includes(true)) {
            setVisible(true)
        } else {
            setVisible(false)
        }
    }, [showControl])

    useEffect(() => {
        setPopupsControl(prev => {
            const newPopupsControl = {...prev}
            newPopupsControl.showPopUp = showPopUp
            newPopupsControl.hidePopUp = hidePopUp
            return newPopupsControl
        })
    }, [setPopupsControl, showPopUp])

    return (
        visible
            ?   
                <div className='popup-display'>
                    { showControl.cookies && <AcceptCookies hidePopup={ () => hidePopUp('cookies') } /> }
                    { showControl.serviceOffering && <ServiceOffering hidePopup={ () => hidePopUp('serviceOffering') } /> }
                    { showControl.welcomeBack && <WelcomeBack hidePopup={ () => hidePopUp('welcomeBack') } /> }
                    { showControl.requestMeeting && <RequestMeeting hidePopup={ () => hidePopUp('requestMeeting') } /> }
                </div>
            : null
    )
}

export default PopUpDisplay;